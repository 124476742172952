
/**
 * To avoid typos and to allow changing the URLs later all links should use this enum
 * to organize existing pages.
 */
export enum Routes {
  // #region public pages

  Home = "/",
  About = "/about",
  Contact = "/contact",
  DataProtection = "/data-protection",
  Netiquette = "/netiquette",
  TermsOfUse = "/terms-of-use",
  Imprint = "/imprint",
  ChallengePage = "/challenges/[slug]",

  // #region Tools
  FAQ = "/tools/faq",
  Pubtools = "/tools/pubtools",
  // #endregion

  // #region user handling
  ConfirmAccount = "/user/confirm-account/[id]/[token]",
  ConfirmEmailChange = "/user/confirm-email/[id]/[token]",
  ConfirmPasswordReset = "/user/reset-password/[id]/[token]",
  ForgotPassword = "/user/forgot-password",
  Login = "/user/login",
  Registration = "/user/register",
  OAuthVerification = "/user/oauth-verification",
  // #endregion

  // #region public project data/pages
  MarketOfCategories = "/market/[id]/categories",
  MarketOfIdeas = "/market/[id]/ideas",
  MarketOfProjects = "/market/[id]/projects",
  MarketOfPrograms = "/market",
  MarketOfSupportRequests = "/market/[id]/support-requests",
  ProjectPage = "/projects/[slug]", // also works for ID
  CreateIdea = "/market/[id]/create-idea",
  ChooseProgramToCreateIdea = "/market/create-idea",
  CreateProject = "/market/[id]/create-project?inspiration=[inspirationId]",
  ProjectApplication = "/projects/[slug]/apply",
  // #endregion

  // #endregion public pages

  // #region user area, restricted for logged in users
  UserDashboard = "/user/dashboard",
  Feedback = "/user/feedback",
  ImportProject = "/user/import-project",
  MySupportOffers = "/user/supportoffers",
  MyProjects = "/user/projects",
  SingleDiscussionPage = "/user/feedback/[id]",
  // #endregion user area, restricted for logged in users

  // #region project pages for project team members
  ProjectProfile = "/projects/[slug]/profile",
  ProjectProfileEdit = "/projects/[slug]/profile/edit",
  ProjectSelectChallenge = "/projects/[slug]/challenge-proposal/select-challenge",
  ProjectConcretization = "/projects/[slug]/challenge-proposal/concretizations",
  ProjectConcretizationEdit = "/projects/[slug]/challenge-proposal/concretizations/edit",
  ProjectFeedbackDashboard = "/projects/[slug]/feedback",
  ProjectFeedbackPage = "/projects/[slug]/feedback/feedback",
  ProjectMap = "/projects/[slug]/profile/map",
  ProjectMembers = "/projects/[slug]/team/members",
  ProjectNetworkDashboard = "/projects/[slug]/network",
  ProjectNetworkCreateSupportRequest = "/projects/[slug]/network/supportrequests/create",
  ProjectNetworkSupportRequests = "/projects/[slug]/network/supportrequests",
  ProjectNetworkSupportOffersOfOneSupportRequest = "/projects/[slug]/network/supportrequests/[id]/supportoffers",
  ProjectPartners = "/projects/[slug]/plan/partners",
  ProjectOwnContributions = "/projects/[slug]/plan/own-contributions",
  ProjectPlan = "/projects/[slug]/plan",
  ProjectPlanDescription = "/projects/[slug]/plan/description",
  ProjectPlanTargetgroupsImpact = "/projects/[slug]/plan/targetgroups-impact",
  ProjectPlanTasks = "/projects/[slug]/plan/tasks",
  ProjectPlanWorkPackages = "/projects/[slug]/plan/work-packages",
  ProjectPlanTimetable = "/projects/[slug]/plan/timetable",
  ProjectPlanResourceRequirements = "/projects/[slug]/plan/resource-requirements",
  ProjectPlanResourceCostCategories = "/projects/[slug]/plan/cost-categories?proposal=[proposal]",
  ProjectPlanFinances = "/projects/[slug]/plan/finances",
  ProjectProposals = "/projects/[slug]/challenge-proposal",
  ProjectProposalAttachments = "/projects/[slug]/challenge-proposal/attachments",
  ProjectStandingData = "/projects/[slug]/challenge-proposal/standing-data",
  ProjectStandingDataEdit = "/projects/[slug]/challenge-proposal/standing-data/edit",
  ProjectProposalChallengeModuleInactive = "/projects/[slug]/challenge-proposal/module-inactive-proposal-details",
  ProjectProposalInactive = "/projects/[slug]/challenge-proposal/inactive-proposal-details?proposal=[proposal]",
  ProjectProposalAfterSubmission = "/projects/[slug]/challenge-proposal/post-submission",
  ProjectProposalSubmission = "/projects/[slug]/challenge-proposal/submission",
  ProjectTeamMeeting = "/projects/[slug]/team/teammeeting",
  ProjectTeamUpload = "/projects/[slug]/team/team-uploads",
  ProjectSummary = "/projects/[slug]/project-summary",
  // #endregion

  // #region tenant area
  TenantCreate = "/tenant/create",
  TenantCreateProgram = "/tenant/[id]/create-program",
  TenantDashboard = "/tenant/[id]/dashboard",
  TenantEdit = "/tenant/[id]/edit",
  TenantProgramCategories = "/tenant/[id]/program/[programId]/categories",
  TenantProgramCategoriesAdd = "/tenant/[id]/program/[programId]/categories/add",
  TenantProgramCategoriesEdit = "/tenant/[id]/program/[programId]/categories/[category]/edit",
  TenantProgramEdit = "/tenant/[id]/program/[programId]/edit",
  TenantView = "/tenant/[id]",
  // #endregion

  // #region program area
  ProgramDashboard = "/program/[id]/dashboard",
  CommunityManagerProgramCategories = "/program/[id]/categories",
  ProgramView = "/program/[id]",
  ProgramProjects = "/program/[id]/projects",
  ProgramIdeas = "/program/[id]/ideas",
  ManagerProjectDetails = "/program/[id]/projects/[projectId]",

  // @todo multi -> remove when useCurrentProgram() is obsolete
  SingleMultiCurrentProgram = "/program",
  // #endregion

  // #region platform manager area
  PlatformManagerDashboard = "/platform/manage",
  PlatformSystemOverview = "/platform/system",
  PlatformSystemIcons = "/platform/icons",
  PlatformPageAccessOverview = "/platform/page-access",
  // #endregion

  // #region basic administrative pages for process manager / admin
  // @todo multi: überarbeiten
  ManagerUserSearch = "/user/manage",
  ManagerUserDetails = "/user/manage/[id]",
  /** @deprecated ersetzen durch das jeweilige Manager-Dashboard */
  AdminDashboard = "/management",

  /** @deprecated ersetzen durch ProgramProjects oder Managerrollen-spezifische Project-Pages, siehe https://futureprojects.atlassian.net/browse/FCP-1738 */
  AdminProjectSearch = "/management/projects",
  /** @deprecated */
  AdminProjectProfile = "/management/projects/[id]/profile",
  // #endregion

  // #region challenge/fund management
  // to differentiate the overview over challenges/funds we use a type-parameter
  AdminChallengeOverview = "/management/challenges?type=[type]",
  // the creation of a challenge may result in a BasicChallenge or a Fund. To diffenciate we use a type-parameter
  AdminChallengeCreate = "/management/challenges/create?type=[type]",

  AdminChallengeDetails = "/management/challenges/[id]/details",
  AdminChallengeDetailsEdit = "/management/challenges/[id]/details/edit",
  AdminChallengeConcretization = "/management/challenges/[id]/concretization",
  AdminChallengeConcretizationCreate = "/management/challenges/[id]/concretization/add",
  AdminChallengeConcretizationEdit = "/management/challenges/[id]/concretization/edit",
  AdminChallengeProcedureAndTimeline = "/management/challenges/[id]/procedure-timeline",
  AdminChallengeProcedureAndTimelineEdit = "/management/challenges/[id]/procedure-timeline/edit",
  AdminChallengeViewProposals = "/management/challenges/[id]/view-proposals",
  AdminChallengeSelectProposals = "/management/challenges/[id]/select-proposals",
  AdminChallengeGrant = "/management/challenges/[id]/grant",
  AdminChallengeGrantEdit = "/management/challenges/[id]/grant/edit",

  // transitioning the challenge from one state to another are handled by one transition-page
  AdminChallengeTransitionPage = "/management/challenges/[id]/transition?transition=[transition]",
  // #endregion challenges/fund workflow

  // #region feedback for management
  // @todo: in communitymanager folder verschieben
  AdminFeedbackInvitationTimeline = "/management/feedback/invitation/[id]/timeline",
  AdminFeedbackInvitationActivate = "/management/feedback/invitation/[id]/activate",
  AdminFeedbackInvitationCreate = "/management/feedback/invitation/create",
  AdminFeedbackInvitationEdit = "/management/feedback/invitation/[id]/edit",
  AdminFeedbackInvitationResults = "/management/feedback/invitation/[id]/results",
  AdminFeedbackInvitations = "/management/feedback/invitation",
  AdminFeedbackInvitationView = "/management/feedback/invitation/[id]",
  // #endregion

  // download-page for files/pdf
  DownloadTriggerPage = "/download/[type]/[id]",
}

/**
 * Routes to the backend, especially to download files
 */
export enum BackendRoutes {
  ProposalAttachmentDownload = "/proposal_attachments/[id]/download",
  ProposalPdfDownload = "/proposals/[id]/proposal-pdf-download",
  ProposalTeamPdfDownload = "/proposals/[id]/team-pdf-download",
  ProjectPdfDownload = "/projects/[id]/download-pdf",
  TeamUploadDownload = "/team_uploads/[id]/download",
}