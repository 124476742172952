import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IUser, UserRole, IUserObjectRole, IUserProjectRole, IUserManagementRole } from "@api/schema"
import { TEAM_ROLES } from "@basics/pageAccess"
import { IRequestState, mergeRequestStates } from "@redux/helper/state"
import { AppState } from "@redux/reducer"
import { selectCurrentUser, selectIsAuthenticated } from "@redux/reducer/auth"
import { selectCollectionUsecaseState } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { loadCurrentUserAction } from "@redux/usecases/userAccount/actions"
import { selectLoadingCurrentUserUsecaseState } from "@redux/usecases/userAccount/selectors"
import { filterRoles, UOR_MANAGER_ROLES } from "@services/userObjectRolesHelper"

import { usecaseKeyForLoadCollection } from "./useEntityCollection"

/**
 * structure of what the useCurrentUser hook returns
 */
export interface IUseCurrentUserRequestData {
  currentUser: IUser
  isAuthenticated: boolean
  /** true, if the user has at least one manager role or is PlatformManager */
  isManager: boolean
  /** @deprecated */
  isProcessManager: boolean
  isPlatformManager: boolean
  roles: UserRole[]
  userObjectRoles: IUserObjectRole[]
  projectRoles: IUserProjectRole[]
  managerRoles: IUserObjectRole[]
  mergedUserRequest: IRequestState
}

/**
 * Hook to use the current user.
 * Takes it from the state or loads it
 * if the user is authenticated but there is no currentUser object.
 *
 * @param loadIfNotPresent Should the hook load the current user if it is not in state?
 * To avoid unnecessary loads, e.g. if no project slug is given the user who wants to access a page is not important.
 * default: true
 * @returns data regarding the current user
 */
export const useCurrentUser = (loadIfNotPresent = true): IUseCurrentUserRequestData => {
  const dispatch = useDispatch()

  const isAuthenticated = useSelector((state: AppState) => selectIsAuthenticated(state))
  const currentUser = useSelector((state: AppState) => selectCurrentUser(state))
  const userRequest = useSelector((state: AppState) => selectLoadingCurrentUserUsecaseState(state))

  // do not iterate over all UORs but use the UOR request for the currentUser
  const usecaseKey = usecaseKeyForLoadCollection(null, null, currentUser?.["@id"])
  const userObjectRolesRequest = useSelector((state: AppState) => selectCollectionUsecaseState(state, EntityType.UserObjectRole, usecaseKey))
  const userObjectRoles = userObjectRolesRequest?.getItems<IUserObjectRole>()

  const roles = useSelector((state: AppState) => state.auth.roles)
  const isProcessManager = roles?.includes(UserRole.ProcessManager)
  const isPlatformManager = roles?.includes(UserRole.PlatformManager)

  const mergedUserRequest = mergeRequestStates(userRequest, userObjectRolesRequest)
  const projectRoles = filterRoles<IUserProjectRole>(userObjectRoles, TEAM_ROLES)
  const managerRoles = filterRoles<IUserManagementRole>(userObjectRoles, UOR_MANAGER_ROLES)
  const isManager: boolean = isPlatformManager || managerRoles.length > 0

  useEffect(() => {
    if (isAuthenticated && loadIfNotPresent
      && (
        // user is not loaded yet and not currently loading
        (!currentUser || (!userRequest?.loaded && !userRequest.isLoading))
        // or UserObjectRoles is not loaded && not currently loading to make sure, they exists also when currentUser data exists
        || (!userObjectRolesRequest?.loaded && !userObjectRolesRequest?.isLoading)
      )) {
      dispatch(loadCurrentUserAction())
    }
  }, [isAuthenticated, currentUser, loadIfNotPresent, JSON.stringify(userRequest), JSON.stringify(userObjectRolesRequest)])

  return { currentUser, isAuthenticated, isManager, isPlatformManager, isProcessManager, roles, userObjectRoles, projectRoles, managerRoles, mergedUserRequest }
}