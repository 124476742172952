import { Field, Form, Formik, FormikErrors } from 'formik'
import { useState } from 'react'
import { FormGroup } from 'reactstrap'

import FormikInput from "@components/common/form/FormikInput"
import Icon from "@components/common/Icon"
import { withDynamicNamespaces } from '@components/hoc/withDynamicNamespaces'
import { NamespaceShortcut, prefixedKey, useDynamicTranslation } from '@services/i18n'

import ActionIcon from '../ActionIcon'


const usedNamespaces: NamespaceShortcut[] = ["faq-meta"]
interface ISearchParams {
  pattern: string
}

interface IProps {
  searchParams: ISearchParams
  onSubmit: (values: ISearchParams) => void
  onCancel: () => void
}
/**
 * It is a formik-component, which provides a searchfield for the FAQ-System.
 * The user has to enter at least 3 characters in order to start the search.
 * It is part of the FAQSearchView, which is used within the AreaLayer.
 */

const FAQSearchInputField = ({ searchParams, onSubmit, onCancel }: IProps): JSX.Element => {
  const t = useDynamicTranslation()

  const [isCancelIconVisible, setIsCancelIconVisible] = useState(false)

  const validate = (values: ISearchParams): FormikErrors<ISearchParams> => {
    const errors: { [key: string]: string } = {}
    if (values.pattern && values.pattern.length < 3) {
      // shows an error message as well as calls onCancel, e.g. to reset the view
      errors.pattern = prefixedKey("faq-meta", "validation.searchPatternTooShort")
      onCancel()
      setIsCancelIconVisible(false)
    } else {
      // On each succesful validation, start the search
      if (values.pattern !== "") {
        onSubmit(values)
        if (!isCancelIconVisible) setIsCancelIconVisible(true)
      }
    }
    return errors
  }
  return <>
    <Formik<ISearchParams>
      initialValues={searchParams}
      onSubmit={onSubmit}
      validate={validate}
      validateOnChange={true}
    >
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup className="faq__search">
            <span className="faq__search__icon" onClick={() => {
              if ((Object.keys(validate(values)).length === 0) && values.pattern !== "") {
                onSubmit(values)
              }
            }
            }>
              {
                // the icon represents the magnifier icon.
              }
              <Icon
                name="search"
                size={18}
                tooltip={t("faq-meta", "search.title")}
              />
            </span>

            <Field
              component={FormikInput}
              help={prefixedKey("faq-meta", "search.help")}
              label={prefixedKey("faq-meta", "search.label")}
              placeholder={prefixedKey("faq-meta", "search.placeholder")}
              maxLength={100}
              name="pattern"
              noGroup={true}
              type="text"
              value={values.pattern}
            />
            {
              isCancelIconVisible &&
              <ActionIcon
                onclick={() => {
                  values.pattern = ''
                  setIsCancelIconVisible(false)
                  onCancel()
                }}
                title={t("faq-meta", "search.cancel")}
                icon={"cancel"}
                className="faq__search__cancel-icon actionicon" />}
          </FormGroup>
        </Form>
      )}
    </Formik>
  </>
}

export default withDynamicNamespaces<IProps>(FAQSearchInputField, usedNamespaces)