import { Action, ActionFromReducersMapObject, combineReducers } from "redux"

import { oidcReducer, OIDCStateSelector } from "@modules/oidc/src"
import { entityUsecaseReducer, statisticsReducer } from "@redux/helper/reducers"
import { marketPlaceSearchStateReducer } from "@redux/usecases/marketplace"
import { onboardingReducer } from "@redux/usecases/onboarding/reducers"

import { applicationStateReducer } from "./applicationStates"
import { authReducer } from "./auth"
import { dataReducer } from "./data"
import { notificationReducer } from "./notifications"
import { platformReducer } from "./platform"
import { requestsReducer } from "./requests"
import { verificationsReducer } from "./verification"

/**
 * Map of all reducers.
 * Only used to be able to define type AppAction.
 */
const mapReducers = {
  /** simple states of application functions, e.g. of the Flyout (used for FAQ) */
  applicationStates: applicationStateReducer,
  /** authentification-state of the user */
  auth: authReducer,
  /** internal data store for all unfiltered data coming from the API */
  data: dataReducer,
  /** search parameters to be used in the marketplace */
  marketplaceSearchState: marketPlaceSearchStateReducer,
  /** pending "onboarding" elements, i.e. elements the user created before logging in / registering */
  onboardingData: onboardingReducer,
  /** array of notifications, to be shown to the user */
  notifications: notificationReducer,
  /** meta-data about the platform */
  platform: platformReducer,
  /** states of (temporary) requests to the API */
  requests: requestsReducer,
  /** contains statistics data */
  statistics: statisticsReducer,
  /** boolean, if a verification operation was successful */
  verification: verificationsReducer,

  /** complex EntityType-specific "request" states for usecases (both SingleEntity and filteredCollection) */
  entityUsecases: entityUsecaseReducer,

  /** OIDC specific states */
  oidc: oidcReducer,
}

/**
 * Accessor to the embedded state of the OIDC module
 */
export const selectOIDCState: OIDCStateSelector = (state: AppState) => state.oidc


/**
 * This Redux-Element combines all existing reducers to that, what we call the Store/the State.
 * The State can be seen and watched with the Redux-Devtool in the browser for debugging.
 *
 * Reducers produce a cut-out of the available data in the local redux-store, so that this filtered data
 * is easier accessable for special operations, e.g. for managing.
 *
 * data: unfiltered data of everything
 * requests: meta-information about (temporary) requests to the API
 * all other: specific views, dependent on use-case
 *
 * reducer will/should be called, when (new) data comes from the backend/api
 * selectors may be called directly in use-cases in the application-context (pages, forms, ...)
 */
const appReducer = combineReducers(mapReducers)

/**
 * The State of the Application, build from all reducers in src/redux/reduxer/index.ts.
 */
export type AppState = ReturnType<typeof appReducer>

/**
 * Union type of all actions, extracted from mapReducers
 */
type AppAction = ActionFromReducersMapObject<typeof mapReducers>

/**
 * Enhance the default appReducer to allow clearing all state, e.g. after logout
 *
 * @param state the current state
 * @param action any AppAction, e.g. a CurrentUserAction
 */
export const rootReducer = (state: AppState, action: AppAction | IHydrateAction | IClearStorageAction): AppState => {
  if (action.type === StorageUsecase.Clear) {
    return undefined
  }

  // hydrate for next-redux-wrapper @todo
  if (action.type === StorageUsecase.Hydrate) {
    return { ...state, ...action.payload }
  }

  return appReducer(state, action)
}

// #region storage actions

enum StorageUsecase {
  Clear = "CLEAR",
  Hydrate = "HYDRATE"
}

interface IClearStorageAction extends Action<StorageUsecase> {
  type: StorageUsecase.Clear
}

export const clearStoreAction = (): IClearStorageAction => ({
  type: StorageUsecase.Clear
})

/*
 * The hydrate action is defined not as a PlatformAction to avoid a
 * circular dependency between the index reducer file and the platform actions file.
 *
 * Note:
 * The Platform Actions are a part of the platform reducer, which belongs to the AppState.
 * Defining a HydrateAction, which is defined as a PlatformAction in order to reset the AppState is a
 * circular dependency, which should be avoided.
 */

/** for resetting the store with a given data payload */
export interface IHydrateAction extends Action<StorageUsecase> {
  payload: AppState
  type: StorageUsecase.Hydrate
}

/**
 * Action for initialising the state or overwrite parts of an existing state by a given payload
 */
export const hydrateAction = (payload: AppState): IHydrateAction => ({
  payload,
  type: StorageUsecase.Hydrate
})

// #endregion