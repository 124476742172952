import { Col, Row, Spinner } from "reactstrap"

import BaseLayout from "@components/BaseLayout"

interface IProps {
  /** an already translated title */
  title?: string
  /** an already translated description */
  description?: string
}

/**
 * This component provides a spinner page to be shown while request is loading.
 */
const SpinnerPage: React.FC<IProps> = (props: IProps) => {
  const { title, description } = props

  return <BaseLayout pageTitle={title}>
    <Row>
      <Col className="text-center">
        <h1>{title}</h1>
        <p>{description}</p>
        <Spinner />
      </Col>
    </Row>
  </BaseLayout>
}

export default SpinnerPage
