export enum PlatformActionTypes {
  ClearStore = "CLEAR_STORE",

  LoadBackendCommit = "LOAD_BACKEND_COMMIT",
  SetBackendCommit = "SET_BACKEND_COMMIT",

  LoadSysinfo = "LOAD_SYS_INFO",
  SetSysinfo = "SET_SYS_INFO",

  SetAuthTTL = "SET_AUTH_TTL",
}

/**
 * @returns true if the platform is running in a test environment (where certain stuff is not available)
 */
export const platformIsInTestEnvironment = (): boolean => !process.env.NODE_ENV || process.env.NODE_ENV === 'test'

/**
 * @returns true if the platform is running in a development environment
 */
export const platformIsInDevEnvironment = (): boolean => process.env.NODE_ENV === 'development'

/**
 * @returns true if the platform is running in a production environment
 */
export const platformIsInProductionEnvironment = (): boolean => process.env.NODE_ENV === 'production'
