import { all, put, select, takeLatest } from "redux-saga/effects"
import { putWait } from "redux-saga-callback"

import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IIdea, IUser, IUserProjectRole } from "@api/schema"
import { INNER_TEAM_ROLES } from "@basics/pageAccess"
import { IdeaActionTypes } from "@redux/actions/idea"
import { addNotificationAction } from "@redux/actions/notifications"
import { newLoadCollectionAction } from "@redux/helper/actions"
import { selectCurrentUser } from "@redux/reducer/auth"
import { selectMyCreatedIdeas, selectMyMemberships } from "@redux/reducer/myProjects"
import { EntityType } from "@redux/reduxTypes"
import { usecaseKeyForLoadCollection } from "@services/hooks/useEntityCollection"
import { filterRoles } from "@services/userObjectRolesHelper"

export function* ideaWatcherSaga(): any {
  yield all([
    takeLatest(IdeaActionTypes.TriggerNotificationForUnusedIdea, unusedIdeaNotificationSaga),
  ])
}

/**
 * After the user logged in: check if the user has an idea without resulting
 * projects, if yes add a notification.
 */
function* unusedIdeaNotificationSaga() {
  const currentUser: IUser = yield select(selectCurrentUser)

  // Currently all ideas have to be loaded, b/c it is not possible to filter for resultingProjectCount
  // @todo multi low priority, but should be in the future fetched via user statistic as soon as it is available
  // @see https://futureprojects.atlassian.net/browse/FCP-1593
  yield putWait(newLoadCollectionAction(
    EntityType.Idea,
    null,
    usecaseKeyForLoadCollection(null, null, currentUser["@id"]),
    iriFromIModelOrIRI(currentUser),
    true
  ))
  const myIdeas: IIdea[] = yield select(selectMyCreatedIdeas)
  if (myIdeas === null || myIdeas.length === 0) {
    return
  }

  const memberships: IUserProjectRole[] = yield select(selectMyMemberships)
  const activeMemberships = filterRoles<IUserProjectRole>(memberships, INNER_TEAM_ROLES)

  const unusedIdeas = myIdeas.filter(i => i.resultingProjectCount === 0)
  // @todo prüfen bzw dokumentieren, warum auf activeMemberships geprüft wird
  if (unusedIdeas.length > 0 || activeMemberships.length === 0) {
    yield put(addNotificationAction("message.project.createFromIdeaReminder", "info"))
  }
}