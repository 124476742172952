import { useRouter } from "next/router"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IRequestState } from "@modules/frontend-definitions/src"

import { useOIDCProviders } from "./useOIDCProviders"
import { getOIDCConfig, OIDCLocalStorageKeys } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"
import { OIDCRequestScopes } from "../models/request-states"
import { OIDC_PROVIDER_DEFAULT_STATE_VALUE } from "../redux/reducer"
import { fetchOIDCTokenAction } from "../redux/saga"
import { AUTH_STATE_SEPARATOR } from "../utils/util"


/**
 * Return type of the `useOIDCToken` hook.
 */
export type OIDCTokenResult = {
  /** OIDC provider from which the token was requested. */
  provider: IOIDCProvider
  /** ID token of the user, returned by the OIDC provider. */
  idToken: string
  /** Access token for the user, returned by the OIDC provider. */
  accessToken: string
  /** Request state of the OICD token request. */
  request: IRequestState
}

/**
 * Hook to request an OICD token from an OIDC provider.
 * Input data is taken from url parameters and the browser's localStorage.
 */
export const useOIDCToken = (): OIDCTokenResult => {
  const router = useRouter()
  const dispatch = useDispatch()
  const authCode = getOIDCConfig().getStringFromQuery(router, "code")

  const authStateWithProviderShortname = getOIDCConfig().getStringFromQuery(router, "state")

  const storedAuthState = getOIDCConfig().storageAPI.getStorageItem(OIDCLocalStorageKeys.AuthState)
  const storedCodeVerifier = getOIDCConfig().storageAPI.getStorageItem(OIDCLocalStorageKeys.CodeVerifier)

  // @todo oauth: verify that `nonce` in idToken equals storedNonce
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const storedNonce = getOIDCConfig().storageAPI.getStorageItem(OIDCLocalStorageKeys.Nonce)

  // providershortname/authState may not be set, especially in build process when router is not available
  const [providerShortName, authState] = authStateWithProviderShortname
    ? authStateWithProviderShortname.split(AUTH_STATE_SEPARATOR)
    : [null, null]

  const { providers, request: providersRequest } = useOIDCProviders()

  const provider = providers.find(p => p.shortName === providerShortName)

  const idToken = useSelector(getOIDCConfig().selectOIDCState).idToken
  const accessToken = useSelector(getOIDCConfig().selectOIDCState).accessToken
  const request = useSelector(getOIDCConfig().requestStateAPI.selectRequestState(OIDCRequestScopes.FetchOIDCToken))

  const mergedRequest = getOIDCConfig().requestStateAPI.mergeRequestStates(providersRequest, request)

  useEffect(() => {
    getOIDCConfig().loggerAPI.debug("useOIDCToken: useEffect", idToken, authCode, authState, storedAuthState, storedCodeVerifier, provider)
    if (
      // block 1: output data not yet fetched
      (!idToken && !accessToken) // neither token has already been fetched
      // block 2: input data is available
      && authCode // authCode is set in URL params
      // @todo oauth replace with `oidcProvidersRequest.loaded`
      && providers !== OIDC_PROVIDER_DEFAULT_STATE_VALUE // providers have been loaded
      // block 3: request state is ok
      && (!request || !request.loaded)
      && !request.isLoading
      && !request.loadingError
    ) {
      // additional tests with usecase-specific logic
      // @todo oauth this could be done in the saga b/c there exists "default" way to handle errors
      if (
        storedAuthState // auth state available from store
        && authState === storedAuthState // authState from URL params equals the authState in browser storage
        && storedCodeVerifier // code verifier available from store
        && provider // we found the matching provider
      ) {
        getOIDCConfig().loggerAPI.debug("useOIDCToken: dispatch fetchOIDCTokenAction")
        dispatch(fetchOIDCTokenAction(provider, authCode, storedCodeVerifier))
      } else {
        // @todo oauth is this a wild hack for returning errors? or is it new & cool?
        // @todo oauth use keys + translation instead of hard-coded text; but we'd need `t()` for it to work
        dispatch(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.FetchOIDCToken,
          !storedAuthState || !storedCodeVerifier || !storedNonce
            ? "Zugriff auf den lokalen Speicher des Browsers fehlgeschlagen." // "oidc.localStore.fail"
            : authState !== storedAuthState
              ? "Der vom OAuth-Provider zurückgegebene Auth-State stimmt nicht mit dem im lokalen Speicher des Browsers überein." // "oidc.authState.mismatch"
              : !provider
                ? "Die Daten zum OAuth-Provider konnte nicht geladen werden." // "oidc.provider.notFound"
                : "Beim Anmelden mit dem OAuth-Provider ist ein Fehler aufgetreten." // "oidc.anyError"
        ))
      }
    }
    // @todo oauth: on success of fetchOIDCTokenAction we should delete all localStorage content!
  },
    // NOTE: ChatGPT suggests using `router` here
    [
      idToken,
      accessToken,
      authCode,
      authState,
      storedAuthState,
      storedCodeVerifier,
      JSON.stringify(providers),
      provider,
      JSON.stringify(request)
    ]
  )

  return { provider, idToken, accessToken, request: mergedRequest }
}
