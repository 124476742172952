import { captureException } from "@sentry/nextjs"
import Cookies from "js-cookie"
import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects"

import apiClient from "@api/client"
import { IAuthReply } from "@api/schema"
import { AuthActionTypes } from "@basics/auth"
import { Routes } from "@basics/routes"
import { randomIntFromInterval } from "@basics/util-importless"
import {
  refreshTokenAction,
  setJwtAction
} from "@redux/actions/auth"
import { selectAuthExpiresIn, selectCurrentUserId, selectIsAuthenticated, selectRefreshToken, selectRefreshTokenExpiresIn } from "@redux/reducer/auth"
import { logoutAction } from "@redux/usecases/userAccount/actions"
import { authReplyToJwtState, emptyAuthState, timestampPassed, timestampToDate } from "@services/authHelper"
import { setStorageItem } from "@services/localStorage"
import { AUTH_COOKIE_NAME, AUTH_LOCALSTORAGE_NAME, AUTH_REFRESH_THRESHOLD, BASE_URL } from "config"



export function* authWatcherSaga(): any {
  yield all([
    takeLatest(AuthActionTypes.InitAuth, initAuthSaga),
    // we use takeLatest to allow restart of the countdown when the token was refreshed by another tab
    takeLatest(AuthActionTypes.RefreshToken, refreshTokenSaga),
    takeEvery(AuthActionTypes.UserIsIdle, userIsIdleSaga),
  ])
}

/**
 * Triggered once when the application loads in the client and when a logout signal is received via
 * localStorage.
 * Tries to read a (previous) JWT + refresh token from the cookie and stores them in the store,
 * to have the auth data available on every page, e.g. to show login status in the menu, not only
 * on restricted pages. This is also required for restricted pages to work client-side without
 * having to read the cookie again on each navigation.
 */
function* initAuthSaga(): Generator<any, void, any> {
  const authData = Cookies.get(AUTH_COOKIE_NAME)

  // no cookie found, still mark as initialized
  if (!authData) {
    yield put(setJwtAction(emptyAuthState))
    return
  }

  let auth: IAuthReply
  try {
    auth = JSON.parse(authData)
  }
  catch (_err) {
    // cookie could not be parsed, still mark as initialized
    yield put(setJwtAction(emptyAuthState))
    return
  }

  const jwtState = authReplyToJwtState(auth)
  if (!timestampPassed(jwtState.refreshTokenExpiresAt)) {
    // save the encoded token (for the Authorization header) together with the decoded expiration
    // time, username, roles & refresh token in the store
    yield put(setJwtAction(jwtState))
  }
  else {
    // if no token could be decoded (e.g. forged cookie) or the refresh token expired, still
    // mark the authState as initialized
    yield put(setJwtAction(emptyAuthState))
  }

  // trigger the refresh mechanism
  yield put(refreshTokenAction())
}


/**
 * refreshes the token before it expires
 */
function* refreshTokenSaga(): Generator<any, void, any> {
  const expiresIn: number = yield select(selectAuthExpiresIn)
  const refreshExpiresIn: number = yield select(selectRefreshTokenExpiresIn)

  // should not happen, because refreshTokenAction() is triggered right after the new token was received,
  // an expired token cannot be refreshed
  if (refreshExpiresIn <= 0) {
    yield put(logoutAction(Routes.Login, "message.auth.loginExpired", { autoClose: false }))
    return
  }

  if (expiresIn <= AUTH_REFRESH_THRESHOLD || refreshExpiresIn <= AUTH_REFRESH_THRESHOLD) {
    // @todo log warning
    // eslint-disable-next-line no-console
    console.log(`WARNING: Token lifetime (JWT: ${expiresIn} / Refresh: ${refreshExpiresIn} seconds) below refreshThreshold`
      + ` (${AUTH_REFRESH_THRESHOLD} seconds), refreshing now, please check config!`)

    yield call(doRefresh)
    return
  }

  // sleep until short before expiration ...
  const latestRefresh = expiresIn - AUTH_REFRESH_THRESHOLD

  // ... but add a bit of randomization to it to reduce race conditions between multiple tabs:
  // when two (or more) try to refresh at the same time one will fail as the refresh token can
  // only be used once, this would logout all tabs.
  // @todo This is no 100% fix as still the same delay values could be generated, other options
  // would be to decide for a master tab and the others don't trigger refreshes. But how to
  // decide which is the master tab and switch to another when the master is closed?
  // Also we do not want to use the visible tab to be the master as no tab with this application
  // could be visible, but we still want to trigger refreshes, e.g. to enable background activity
  // like polling for new messages etc.
  // Maybe use https://www.npmjs.com/package/browser-tabs-lock or https://www.npmjs.com/package/@webauthid/lock (smaller)
  yield delay(randomIntFromInterval(latestRefresh * 0.5, latestRefresh) * 1000)

  // logged out meanwhile?
  const stillAuthenticated = yield select(selectIsAuthenticated)

  if (stillAuthenticated) {
    yield call(doRefresh)
  }

  // attention: no code after call(doRefresh), we use takeLatest which cancels the execution when
  // the same saga is started again
}

/**
 * Try to refresh the auth token, if successful replace the token in the store
 * and trigger the refresh mechanism again
 */
function* doRefresh(): Generator<any, void, any> {
  const timestamp = Date.now().toString()
  const refreshToken: string = yield select(selectRefreshToken)
  const userId: number = yield select(selectCurrentUserId)

  // just for example: to capture a message to/for Sentry:
  // captureMessage(`${userId} | ${timestamp}: Trying to refresh with token ${refreshToken}`)

  try {
    const newAuth: IAuthReply = yield call(apiClient.refreshAuthToken, refreshToken)
    yield call(saveAuth, newAuth)

    // notify other tabs to re-check their auth cookie and update their auth state
    yield call(setStorageItem, AUTH_LOCALSTORAGE_NAME, timestamp)

    yield put(refreshTokenAction())

    // attention: no code after put(), we use takeLatest which cancels the execution when
    // the same refresh saga is started again
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log("WARNING: Token refresh failed, maybe user locked meanwhile, logging out...")
    captureException(err, { extra: { userId, refreshToken, timestamp } })

    yield put(logoutAction(Routes.Login, "message.auth.refreshFailed", { autoClose: false }))
  }
}

/**
 * stores the authentication token in the state and in a cookie
 */
export function* saveAuth(auth: IAuthReply): Generator<any, void, any> {
  const jwtState = authReplyToJwtState(auth)

  // save the encoded token (for the Authorization header) together with the decoded expiration time,
  // username, roles & refresh token
  yield put(setJwtAction(jwtState ? jwtState : emptyAuthState))

  // save the data in the cookie, this way it is transported to the server if the user
  // hits reload
  // eslint-disable-next-line @typescript-eslint/unbound-method
  yield call(Cookies.set, AUTH_COOKIE_NAME, JSON.stringify(auth), {
    expires: timestampToDate(jwtState.jwtExpiresAt),
    sameSite: "Lax",
    secure: BASE_URL.indexOf("//localhost") === -1,
  })
}

/**
 * Logout user after inactivity timeout, see /components/AppHelper.tsx
 */
function* userIsIdleSaga(): Generator<any, void, any> {
  const isAuthenticated = yield select(selectIsAuthenticated)
  if (isAuthenticated) {
    yield put(logoutAction(Routes.Login, "message.auth.idleLogout", { autoClose: false }))
  }
}