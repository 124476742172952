import useTranslation from "next-translate/useTranslation"

import HtmlContent from "./HtmlContent"

interface IProps {
  content: string // @todo rename globally to keyOrContent
  params?: Record<string, any>
}
/**
 * This component translates the given (keyOr)content by default with the 'common'-i18n namespace
 * and renders it as HtmlContent.
 * If the given content belongs to a different i18n namespace, it must be prefixed accordingly,
 * e.g. "management:my.key".
 * To safely transform "new" i18n style namespaces, use `prefixedKey` method to prefix your key.
 *
 * Note: since the used t function (Translate from next-translate) returns the parameter value
 * if no matching entry is found, this component may also be used with already translated text.
 * However, keep in mind that in this case optional params must be applied beforehand; params handed
 * over to t won't get applied with an already translated string.
 *
 * @TODO: when migrating to the "new" i18n style (with "useDynamicTranslation"), the ("different")
 * namespace would in fact be a NamespaceShortcut and must be mapped to a valid prefix (using
 * `prefixedKey` or `NamespacePath` from `i18n.ts`).
 *
 * @deprecated Because the principle is, not to provide i18n keys to components but already translated
 * texts, to make sure, the namespace is provided by the component, that knows what translations are used,
 * the TranslatedHtml component should by replaces by the HtmlContent component and texts should be
 * provided to it in already translated form.
 */
const TranslatedHtml: React.FC<IProps> = ({ content: keyOrContent, params = null }: IProps) => {
  // NOTE we allow "common" as default for non-prefixed keys, but coders are invited to avoid this
  const { t } = useTranslation("common")
  return <HtmlContent content={t(keyOrContent, params)} />
}

export default TranslatedHtml
