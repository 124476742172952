import { useSelector } from "react-redux"
import { Button } from "reactstrap"

import { isEmptyNullOrUndefinedObject } from "@basics/util-importless"
import ActionIcon from "@components/common/ActionIcon"
import FAQAreaLayer from "@components/common/faq/FAQAreaLayer"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { getFlyoutContentURI } from "@redux/selector/applicationStates"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"

import { FAQHelper, FAQType } from "./FAQHelper"
import { useFAQObject, useSetFAQKey } from "./FAQHooks"


// "faq" is needed to get the FAQ content dynamically
const usedNamespaces: NamespaceShortcut[] = ["faq-meta", "faq"]

/**
 * Base-component for the FAQ-System. It enlists the content of the frequently ask questions (FAQ).
 * The FAQ-System consists of two elements, the FAQMaincomponent, represented by this component and the FAQPopup.
 * The FAQComponent is currently used within the FAQFlyout, and within the faq-page under tools (which is currently
 * not publicly visible b/c its not linked anywhere).
 *
 * To make the component work it is necessary to define
 ** how to update the translationKey from the .json-File, which is used to navigate through the component. (e.g. key update via paramter of the url, or via redux, or via useState,...)
 ** which data basis should be used: provide an object (e.g. from a .json-file) with following structure:
 * {area1: {title, picture, topic1: {title, entry1: {question, answer}, entry2: {question, answer},..., entryN... }, ... topicN... }, ...areaN... }
 *
 * Means: each area has a title, optionally a picture and contains topics. Each topic also has a title and entries. Each entry consists of a question and an answer.
 *
 * The FAQComponent provides 3 layers:
 * 1) areas with its titles and a searchbar
 * 2) topics with its titles and their questions
 * 3) the selected entry of the topic from an area
 *
 * When the user visits the mainpage the areas are visible (first layer).
 * The user can select an area and will be guided to the topics (second layer).
 * Now the user can select a question and will be guided to the answer (third layer - entry).
 *
 * If the user is searching for question, the area titles will disappear and the search result is shown.
 *
 * The user has the option to navigate through all the answers/questions (with arrow buttons) of a certain topic in the third layer.
 *
 * @todo Phillipp:
 * Warum hast du eigentlich alle Komponenten als JSX.Element gebaut? "Mein" Actionicn beispielsweise
 * habe ich annodazumal auf React.FC-Basis gebaut. Aber ich kann den Unterschied grade nicht gut benennen. Du?
 * -> Erinnerung an einen Jakob-Kommenar, dass React.FC nicht mehr genutzt werden sollte, stattdessen JSX.Element
 */

const FAQMainComponent = (): JSX.Element => {
  const t = useDynamicTranslation()
  const setFAQKey = useSetFAQKey()

  const faqNode = useFAQObject()

  const faqKey = useSelector(getFlyoutContentURI)

  return !isEmptyNullOrUndefinedObject(faqNode) &&
    <div className="faq" key={faqKey}>
      <div className="faq__header">
        {faqKey && <ActionIcon
          onclick={() => setFAQKey(FAQHelper.getUpperLayerToBeShown(faqKey))}
          title={t("faq-meta", "page.back")}
          icon={"chevron-left"}
          className="faq__header__back-icon actionicon"
        />
        }
        <Button
          onClick={() => setFAQKey("")}
          title={t("faq-meta", "page.heading")}
          className={"faq__header__button"}
          key={faqKey + "header_button"}
        >
          <h1>{t("faq-meta", "page.heading")}</h1>
        </Button>

        <hr className="faq__header__line-separator" />
      </div>
      <FAQAreaLayer
        faqNode={faqNode as FAQType}
        faqKey={faqKey}
      />
    </div>
}

export default withDynamicNamespaces(FAQMainComponent, usedNamespaces)