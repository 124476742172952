import { RequestStateSelector, IRequestState } from "@modules/frontend-definitions/src"
import { OIDCRequestScopes } from "@modules/oidc/src"
import { AppState } from "@redux/reducer"

/**
 * Accessor to the embedded request states of the OIDC module
 */
export const selectOIDCRequestState: RequestStateSelector<OIDCRequestScopes> =
  (scope: OIDCRequestScopes): (state: AppState) => IRequestState =>
    (state: AppState): IRequestState => {
      switch (scope) {
        case OIDCRequestScopes.LoadOIDCProviders:
          return state.requests.oidcLoadProvidersRequest
        case OIDCRequestScopes.FetchOIDCToken:
          return state.requests.oidcFetchTokenRequest
        case OIDCRequestScopes.LoginWithOIDCToken:
          return state.requests.oidcLoginWithTokenRequest
      }
    }