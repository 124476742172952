
import { useRouter } from 'next/router'
import { DynamicNamespacesProps, I18nDictionary, I18nProviderProps } from 'next-translate'
import I18nProvider from 'next-translate/I18nProvider'
import React, { createContext, useEffect, useMemo, useState } from 'react'

import { loadLocaleFrom } from 'i18n'

export const LoadedI18nNamespacesContext = createContext<Record<string, I18nDictionary>>(null)

/**
 * Adapted implementation to fix bug in original DynamicNamespaces implementation:
 * content inside DynamicNamespaces does not update immediately.
 *
 * Adapted solution from https://github.com/aralroca/next-translate/issues/591
 *
 * - no “dynamic” callback, b/c we don't load our i18n files in this dynamic way (the issue opener wants to load a file *per component* depending on widget.name)
 * - resolve Promise from loadNamespaces (typescript warning)
 * - move fallback condition before success code (code readability)
 * - other way to construct the React return value (don't know if it's necessary)
 * - only load locales if component is still mounted (to prevent "unmounted component" errors in browser console)
 * - only load locales if not already loaded (to optimize performance)
 * - provide loaded namespaces/locales to child components by using a Context (so children can react on availability)
 *
 * @param DynamicNamespacesProps.namespaces defines the used namespaces: complete pathes to the namespaces, not the NamespaceShortcuts!
 * @param DynamicNamespacesProps.fallback a fallback to display, if the namespaces could not be found
 * @param DynamicNamespacesProps.children components, which are using the DynamicNamespaces
 * @returns
 */
export const DynamicNamespaces = ({
  namespaces = [], // complete pathes to the namespaces, not the NamespaceShortcuts!
  fallback,
  children
}: DynamicNamespacesProps): React.FunctionComponentElement<I18nProviderProps> | React.ReactNode => {
  const { locale } = useRouter()

  const [loaded, setLoaded] = useState<boolean>(false)
  const [pageNs, setPageNs] = useState<I18nDictionary[]>([])

  // Flag to cleanup async method if unmounted in between to fix the following warning:
  // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
  // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  // see https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f
  let isMounted = true

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])

  /**
   * The (structured) map of (namespaceshortcut -> dictionary) for all already loaded namespace objects.
   */
  let loadedStructuredNamespaces: Record<string, I18nDictionary> = null

  useEffect(() => {
    setLoaded(false)
    const loadNamespaces = async () => {
      if (isMounted) {
        const pageNamespaces = await Promise.all(
          namespaces
            .map((ns) => {
              // If the namespace object has already been loaded, we do NOT load it again,
              // instead we take it from the array of already loaded namespace objects `structuredNamespaces`.
              // NOTE that order matters; we cannot simply skip it, since `structuredNamespaces` is built
              // using array indices.
              if (loadedStructuredNamespaces?.[ns]) {
                return loadedStructuredNamespaces[ns]
              } else {
                return loadLocaleFrom(locale, ns) as Promise<I18nDictionary>
              }
            })
        )
        setPageNs(pageNamespaces)
        setLoaded(true)
      }
    }

    loadNamespaces()
      // eslint-disable-next-line no-console
      .catch(console.error)
  }, [locale, JSON.stringify(namespaces)])

  loadedStructuredNamespaces = useMemo(() => {
    return namespaces.reduce((obj: Record<string, I18nDictionary>, ns, i) => {
      obj[ns] = pageNs[i]

      return obj
    }, {})
  }, [namespaces, pageNs])

  if (!loaded) {
    return fallback || null
  }

  return (React.createElement(
    I18nProvider,
    { lang: locale, namespaces: loadedStructuredNamespaces },
    <LoadedI18nNamespacesContext.Provider value={loadedStructuredNamespaces}>
      {children}
    </LoadedI18nNamespacesContext.Provider>
  ))
}
