import classNames from "classnames"
import { useEffect, useState } from "react"

import { SCROLLABLE_MAIN_PAGE } from "@basics/components"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { PAGE_HEADER_ID } from "@components/layout/Header"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { useTooltip } from "@services/tooltipHook"

import Icon from "./Icon"

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

const USE_TRESHOLD_TO_SHOW_BUTTON = true
export const TRESHOLD_TO_SHOW_SCROLL_TO_TOP_BUTTON = 500

/**
 * Shows a button to scroll to the header of the base-layout on click.
 *
 * Button is shown, when the user has scrolled over the treshold.
 *
 * NOTE: b/c this component listens to the scroll event listener the listenToScroll()
 * is called very often! This may lead to performance issues.
 * To switch off the performance intensive behavior just cut the listener by
 * setting the USE_TRESHOLD_TO_SHOW_BUTTON switch to false.
 */
const ScrollToTopButton = (): JSX.Element => {
  const t = useDynamicTranslation()
  const [isVisible, setIsVisible] = useState(!USE_TRESHOLD_TO_SHOW_BUTTON)

  /** the element that is scrolled (is not the document, but a part of it) */
  let scrollablePage: HTMLElement = null
  const { tooltipTargetRef, ToolTip } = useTooltip(t("base-layout", "scrollToTop"))
  // default classname is changed in the useEffect that listens to the changing isVisible
  const className = classNames(
    "round-icon-btn",
    "scroll-to-top-btn",
    { "scroll-to-top-btn--hide": !isVisible }
  )

  // add and remove event listener
  useEffect(() => {
    if (USE_TRESHOLD_TO_SHOW_BUTTON) {
      scrollablePage = document?.getElementById(SCROLLABLE_MAIN_PAGE)
      scrollablePage?.addEventListener("scroll", listenToScroll)
      return () => {
        scrollablePage?.removeEventListener("scroll", listenToScroll)
      }
    }
  }, [])

  /** callback to be called when user scrolls */
  const listenToScroll = () => {
    setIsVisible(scrollablePage.scrollTop > TRESHOLD_TO_SHOW_SCROLL_TO_TOP_BUTTON)
  }

  /** When the user clicks on the button, scroll to the top of the document */
  const onClick = () => {
    // smooth scrolling does not work: it stops at the end of the header, instead of going to the start
    // document.getElementById(PAGE_HEADER_ID).scrollIntoView({ behavior: "smooth", inline: "start" })
    document.getElementById(PAGE_HEADER_ID).scrollIntoView({ behavior: "auto", inline: "start" })
  }

  return <>
    <button
      className={className}
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
        onClick()
      }}
      onKeyDown={event => {
        if (event.key === "Enter") {
          event.stopPropagation()
          event.preventDefault()
          onClick()
        }
      }}
      tabIndex={isVisible ? 0 : null}
      ref={tooltipTargetRef}
    >
      <Icon name="chevron-up" size={35} />
    </button>
    {isVisible && ToolTip}
  </>
}

export default withDynamicNamespaces(ScrollToTopButton, usedNamespaces)