import { platformIsInTestEnvironment } from "@basics/platform"
import { NamespacePath, NamespaceShortcut, fallbackStringWhenLoading } from "@services/i18n"

// we use an adapted DynamicNamespaces instead of "next-translate/DynamicNamespaces"
// b/c of bugs (see comments on our implementation)”
import { DynamicNamespaces } from "./DynamicNamespaces"

/**
 * A higher order component (HOC) that allows dynamic loading of i18n namespaces.
 * Use `useDynamicTranslation` inside of wrapped components to get an adapted version of the `t` function.
 *
 * @see also src/services/i18n.ts
 *
 * @param Component The component to be wrapped
 * @param namespaceShortcuts A list of namespace shortcuts as defined in src/services/i18n.ts, that will be expanded to the full paths
 * @returns The wrapped component
 */
// NOTE using "function" instead of => seems to be relevant for HOC, see also withAuth
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function withDynamicNamespaces<T>(
  Component: React.FC,
  namespaceShortcuts: NamespaceShortcut[]
): React.FC<T> {
  /*
   * We do not want to translate anything in a test environment.
   * Also, we would get an "Error: Uncaught [RangeError: Incorrect locale information provided]" if we
   * returned the DynamicNamespaces wrapping.
   */
  if (platformIsInTestEnvironment()) {
    const wrapper = (props: T) => {
      return <Component {...props} />
    }
    return wrapper
  } else {
    const wrapper = (props: T) => {
      return <DynamicNamespaces
        namespaces={namespaceShortcuts.map(n => NamespacePath[n])}
        fallback={fallbackStringWhenLoading}>
        <Component {...props} />
      </DynamicNamespaces>
    }

    return wrapper
  }
}
