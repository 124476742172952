import Link from "next/link"

import { Routes } from "@basics/routes"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  isMarketplace?: boolean
  href?: string
}

/**
 * this component provides the logo with or without link, depending on if its shown on marketplace or not
 */
const HeaderLogo: React.FC<IProps> = ({ href, isMarketplace }: IProps) => {
  const t = useDynamicTranslation()

  if (isMarketplace) {
    return <div className="header-logo no-link-underline"
      aria-label={t("base-layout", "logo")}
    />
  }

  return <div>
    <Link href={href ? href : Routes.MarketOfPrograms}
      title={t("base-layout", "logoLink")}
      className="header-logo no-link-underline"
    />
  </div>

}

export default withDynamicNamespaces<IProps>(HeaderLogo, usedNamespaces)
