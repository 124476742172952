/** *************************************************************************************************
 * This enum defines the usecases around the "user account".
 * "export" is for tests.
 */
export enum UserAccountUsecases {
  /**
   * load current user
   */
  LoadCurrentUser = "_usecase_load_current_user",
  /**
   * login
   */
  Login = "_usecase_login",
  /**
   * post login: load currentUser data, trigger refreshToken (and maybe more)
   */
  PostLoginFetchUserData = "_usecase_post_login_fetch_user_data",
  /**
   * processOnboardingDataAfterLogin - triggers "finalization of onboarding process", chores to do after login
   */
  ProcessOnboardingDataAfterLogin = "_message_process_onboarding_data_after_login",
  /**
   * logout
   */
  Logout = "_usecase_logout",
  /**
   * forgot password
   */
  ForgotPassword = "_usecase_forgot_password",
}