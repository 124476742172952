import { all, call, takeLatest } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import { IProject } from "@api/schema"
import { ICreateProjectAction, ProjectActionTypes } from "@redux/actions/project"
import { createModelAction } from "@redux/helper/actions"
import { EntityType } from "@redux/reduxTypes"


export function* projectWatcherSaga(): any {
  yield all([
    takeLatest(ProjectActionTypes.CreateNewProject, withCallback(createProjectSaga)),
  ])
}

/**
 * Create a new project for an already logged in user and loads the user again, when finished as well as
 * loads the projects of the user.
 *
 * Uses the createProjectSaga in redux/saga/projects.ts to make the api call to the backend for creating the project.
 *
 * @param action ICreateProjectAction
 */
function* createProjectSaga(action: ICreateProjectAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // NOTE this is a (early) prototype for a new style of "calling sub-sagas but avoiding that they call callbackActions".
  // REASON: we want to call onSuccess and setSubmitting when the actual ("our") saga is completed, not when any
  // sub-sagas are completed (see FCP-621 for an example, FCP-829 for another).
  // METHOD: Previously we cached the action.action callbacks and set them to null, but this seems a bit odd.
  // Instead, we call sagas with explicit callbacks passed (see call of createModelAction below).
  // Check FCP-853 for more notes on the refactoring process.

  // call createModelAction without onSuccess or setSubmitting; only setErrors should be present
  const project: IProject = yield putWait(createModelAction(EntityType.Project, action.projectCreationDto, { setErrors }))

  if (!project) {
    // Since we disabled setSubmitting for the sub-saga, we must call setSubmitting(false) now so that calling code may handle it,
    // i.e. reactivate the submit button in the form.
    // NOTE that we DO NOT call setSubmitting at the end of this createProjectSaga, to avoid enabling the submit button
    // before the routing to the newly created project has happened (see FCP-829)
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }

  // call onSuccess, if defined, after necessary data is updated
  if (onSuccess) {
    yield call(onSuccess, project)
  }

  // Since we disabled setSubmitting for the sub-saga, we should call setSubmitting(false) now so that calling code may handle it,
  // i.e. reactivate the submit button in forms.
  // NOTE that we DO NOT call setSubmitting at the end of this createProjectSaga, to avoid enabling the submit button
  // before the routing to the newly created project has happened (see FCP-829)
  // if (setSubmitting) {
  //   yield call(setSubmitting, false)
  // }

  return project
}
