
/**
 * A generally usable logger API.
 * May be implemented by us, but is also implemented by `loglevel`'s `log.Logger`.
 * Use `log.getLogger('deepl')` to obtain an instance that may be used everywhere
 * where we require an `ILoggerAPI`.
 *
 * Add more methods (from `loglevel`'s `log.Logger`, to keep it compatible) if required.
 *
 * For copyright claims:
 * // Originally from Definitely Typed, see:
 * // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/b4683d7/types/loglevel/index.d.ts
 * // Original definitions by: Stefan Profanter <https://github.com/Pro>
 * //                          Gabor Szmetanko <https://github.com/szmeti>
 * //                          Christian Rackerseder <https://github.com/screendriver>
 */
export interface ILoggerAPI {

  /**
   * Output trace message.
   *
   * @param msg any data to log
   */
  trace(...msg: any[]): void

  /**
   * Output debug message.
   *
   * @param msg any data to log
   */
  debug(...msg: any[]): void

  /**
   * Output debug message.
   *
   * @param msg any data to log
   */
  log(...msg: any[]): void

  /**
   * Output info message.
   *
   * @param msg any data to log
   */
  info(...msg: any[]): void

  /**
   * Output warn message.
   *
   * @param msg any data to log
   */
  warn(...msg: any[]): void

  /**
   * Output error message.
   *
   * @param msg any data to log
   */
  error(...msg: any[]): void
}

/**
 * A very basic implementation of `ILoggerAPI`. Writes everything to the console.
 */
export const basicConsoleLogger: ILoggerAPI = {
  // eslint-disable-next-line no-console
  trace: (...msg: any[]): void => console.trace(...msg),
  // eslint-disable-next-line no-console
  debug: (...msg: any[]): void => console.debug(...msg),
  // eslint-disable-next-line no-console
  log: (...msg: any[]): void => console.log(...msg),
  // eslint-disable-next-line no-console
  info: (...msg: any[]): void => console.info(...msg),
  // eslint-disable-next-line no-console
  warn: (...msg: any[]): void => console.warn(...msg),
  // eslint-disable-next-line no-console
  error: (...msg: any[]): void => console.error(...msg)
}

/**
 * A very basic implementation of `ILoggerAPI`. Only writes warnings and errors to the console.
 */
export const importantMessagesConsoleLogger: ILoggerAPI = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  trace: (..._msg: any[]): void => { },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  debug: (..._msg: any[]): void => { },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  log: (..._msg: any[]): void => { },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  info: (..._msg: any[]): void => { },
  // eslint-disable-next-line no-console
  warn: (...msg: any[]): void => console.warn(...msg),
  // eslint-disable-next-line no-console
  error: (...msg: any[]): void => console.error(...msg)
}