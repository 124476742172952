import { Action } from "redux"

import {
  IContactEmail, /* , IProcess */
} from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"

/*
This file contains all actions and needed enums and interfaces, regarding the processes/programs.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

/**
 * defines all possible Action-names regarding the process/program
 */
export enum ProcessActionTypes {
  LoadCurrentProcess = "LOAD_CURRENT_PROCESS",
  SendContactEmail = "SEND_CONTACT_EMAIL"
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * Interface to combine all Actions
 */
export type ProcessActions =
  | ILoadCurrentProcessAction
  | ISendContactEmailAction/* | IUploadProcessLogoAction */


export interface IProcessAction extends Action {
  type: ProcessActionTypes
}

/**
 * Action to trigger the (re)load of the current process
 */
export interface ILoadCurrentProcessAction extends IProcessAction {
  type: ProcessActionTypes.LoadCurrentProcess
}


export interface ISendContactEmailAction extends IProcessAction {
  actions: IFormikActions
  contactEmail: IContactEmail
  type: ProcessActionTypes.SendContactEmail
}


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

/**
 * triggers the loading of a program collection and returns the first program that was found
 * after it was loaded as single entity to have detailResult
 *
 * @todo multi: Bestimmung des "current" programs muss immer über die ID in der URL passieren
 */
export const loadCurrentProcessAction = (): ILoadCurrentProcessAction => ({
  type: ProcessActionTypes.LoadCurrentProcess,
})

export const sendContactEmailAction = (contactEmail: IContactEmail, actions: IFormikActions): ISendContactEmailAction => ({
  actions,
  contactEmail,
  type: ProcessActionTypes.SendContactEmail
})