import axios, { AxiosRequestConfig } from "axios"
import jwtDecode from "jwt-decode"
import { Action } from "redux"
import { all, call, takeLatest, put } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import { BASE_URL } from "@config/index"
import { IHydraCollection } from "@modules/backend-definitions/src"
import { ICallbackHandler } from "@modules/frontend-definitions/src"

import { setOIDCProvidersAction, setOIDCTokensAction, setPlatformAuthReplyAction } from "./reducer"
import { getOIDCConfig } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"
import { OIDCRequestScopes } from "../models/request-states"
import { clearOIDCItemsFromStorage } from "../utils/util"

// #region saga actions

/**
 * Types of actions and corresponding sagas to interact with the host application backend
 * and the OIDC provider.
 */
enum OIDCSagaActionTypes {
  LoadOIDCProviders = "LOAD_OIDC_PROVIDERS",
  FetchOIDCToken = "FETCH_OIDC_TOKEN",
  OIDCLogin = "OIDC_LOGIN",
}

/**
 * Abstract base interface of actions that trigger a saga within the OIDC context.
 */
interface IOIDCSagaAction extends Action {
  type: OIDCSagaActionTypes
}

/**
 * Interface of an action that triggers the loading of a list of supported OIDC providers
 * from the host application backend API.
 */
interface ILoadOIDCProvidersAction extends IOIDCSagaAction {
  type: OIDCSagaActionTypes.LoadOIDCProviders
}

/**
 * Interface of an action that triggers the fetching of the OIDC ID token from the OIDC provider.
 */
interface IFetchOIDCTokenAction extends IOIDCSagaAction {
  provider: IOIDCProvider
  authCode: string
  codeVerifier: string
  type: OIDCSagaActionTypes.FetchOIDCToken
}

/**
 * Interface of an action that triggers the login at the host application backend
 * by using an OIDC ID token.
 */
interface IOIDCLoginAction extends IOIDCSagaAction {
  /** OIDC provider that provides the OIDC ID token. */
  provider: IOIDCProvider
  /**
   * OIDC ID token that has been returned from the OIDC provider after the user authenticated there.
   * Used when `OIDCProvider.userInfoUrl` is not set. User data is only retrieved from this ID token.
   */
  idToken: string
  /**
   * OIDC Access token that has been returned from the OIDC provider after the user authenticated there.
   * Used when `OIDCProvider.userInfoUrl` is set. User data is then fetched from the userInfoUrl.
   */
  accessToken: string
  /** Flag that shows if user has accepted the terms & conditions of the platform. */
  termsAccepted: boolean
  /** A function that will be called if the backend denies access when termsAccepted is missing. */
  reactOnTermsAcceptanceRequired: () => void
  /** A set of handler callbacks, will be called from inside the saga during request processing. */
  handler: ICallbackHandler
  type: OIDCSagaActionTypes.OIDCLogin
}

/**
 * Creates an action to load OIDC provider information from the host application backend.
 */
export const loadOIDCProvidersAction = (): ILoadOIDCProvidersAction => ({
  type: OIDCSagaActionTypes.LoadOIDCProviders
})

/**
 * Creates an action to fetch the OIDC ID token from the OIDC provider, using a matching `authCode`.
 * NOTE: the saga calls OIDC provider's endpoint instead of the host application backend API.
 */
export const fetchOIDCTokenAction = (provider: IOIDCProvider, authCode: string, codeVerifier: string): IFetchOIDCTokenAction => ({
  provider,
  authCode,
  codeVerifier,
  type: OIDCSagaActionTypes.FetchOIDCToken
})

/**
 * Creates an action to login on the host application backend with an OIDC id token
 * or an OIDC/OAuth Access token, provided from a known OIDC provider.
 */
export const oidcLoginAction = (
  provider: IOIDCProvider,
  idToken: string,
  accessToken: string,
  handler: ICallbackHandler,
  reactOnTermsAcceptanceRequired: () => void
): IOIDCLoginAction => ({
  provider,
  idToken,
  accessToken,
  termsAccepted: undefined,
  reactOnTermsAcceptanceRequired,
  handler,
  type: OIDCSagaActionTypes.OIDCLogin
})

/**
 * Creates an action to login on the host application backend with an OIDC id token or an OIDC/OAuth Access token, provided from a known OIDC provider.
 */
export const oidcLoginWithTermsAcceptanceAction = (
  provider: IOIDCProvider,
  idToken: string,
  accessToken: string,
  handler: ICallbackHandler,
  termsAccepted: boolean
): IOIDCLoginAction => ({
  provider,
  idToken,
  accessToken,
  termsAccepted,
  reactOnTermsAcceptanceRequired: undefined,
  handler,
  type: OIDCSagaActionTypes.OIDCLogin
})

// #endregion

// #region sagas

export function* oidcWatcherSaga(): any {
  yield all([
    takeLatest(OIDCSagaActionTypes.LoadOIDCProviders, withCallback(loadOIDCProvidersSaga)),
    takeLatest(OIDCSagaActionTypes.FetchOIDCToken, withCallback(fetchOIDCTokenSaga)),
    takeLatest(OIDCSagaActionTypes.OIDCLogin, withCallback(oidcLoginSaga)),
  ])
}

/**
 * Loads supported providers from the host application backend.
 */
function* loadOIDCProvidersSaga(action: ILoadOIDCProvidersAction): Generator<any, IHydraCollection<IOIDCProvider>, any> {
  try {

    getOIDCConfig().loggerAPI.debug("loadOIDCProvidersSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.LoadOIDCProviders))

    const oidcProviders: IHydraCollection<IOIDCProvider> = yield call(getOIDCConfig().oidcAPI.getOIDCProviders)

    getOIDCConfig().loggerAPI.debug("loadOIDCProvidersSaga: oidcProviders", oidcProviders)

    yield put(setOIDCProvidersAction(oidcProviders))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoadOIDCProviders))

    return oidcProviders
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("loadOIDCProvidersSaga", errorMessage, null, err)

    getOIDCConfig().loggerAPI.debug("loadOIDCProvidersSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoadOIDCProviders, errorMessage))

    yield put(setOIDCProvidersAction(null))

    return null
  }
}

/**
 * Fetches an OIDC ID token from an OIDC provider, using a matching `authCode`.
 */
function* fetchOIDCTokenSaga(action: IFetchOIDCTokenAction): Generator<any, string, any> {
  try {

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.FetchOIDCToken))

    /**
     * Params to be added to the API request to the OIDC provider.
     *
     * NOTE: the param names are standardized for interaction the OIDC provider.
     *
     * @todo oauth refactor the param structure to an (exported) type.
     */
    const urlParams = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: action.provider.clientId,
      code: action.authCode,
      redirect_uri: getOIDCConfig().redirectURI,
      code_verifier: action.codeVerifier
    })

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: urlParams", urlParams)

    let requestConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    // #region add BasicAuth to request config, if required
    if (getOIDCConfig().basicAuth) {
      const [basicAuthUser, basicAuthPassword] = Buffer
        .from(getOIDCConfig().basicAuth, "base64")
        .toString().split(":")

      requestConfig = {
        ...requestConfig,
        auth: {
          username: basicAuthUser,
          password: basicAuthPassword
        }
      }
    }
    // #endregion

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: requestConfig", requestConfig)

    // @todo oauth how do we get rid of "unbound method" error, when at the same time calling apiClient's methods
    // and getOIDCConfig().getOIDCProviders works without error?
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const response = yield call(axios.post, action.provider.tokenUrl, urlParams, requestConfig)

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: response", response)

    const { id_token: idToken, access_token: accessToken } = response.data

    yield put(setOIDCTokensAction(idToken, accessToken))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.FetchOIDCToken))

    return idToken as string
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("fetchOIDCTokenSaga", errorMessage, null, err)

    getOIDCConfig().loggerAPI.debug("fetchOIDCTokenSaga: errorMessage", errorMessage)

    yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.FetchOIDCToken, errorMessage))

    yield put(setOIDCTokensAction(null, null))
    return null
  }
}

/**
 * Expected error message from the backend, if the backend config of the OIDC provider requires the
 * “terms acceptance” by the user, but the client did not sent it.
 */
const TERMS_ACCEPTANCE_REQUIRED_ERROR_MSG = 'Terms acceptance required.'

/**
 * Expected error message from the backend, if a user tries to login with an email adress that is already
 * registered. The frontend will send a message to the platform manager with the required data to
 * integrate this OIDC account with the existing account.
 */
const EMAIL_EXISTS_ERROR_MSG_START = 'A User with email'

/**
 * Performs a login at the host application backend, using an OIDC ID token retrieved earlier.
 */
function* oidcLoginSaga<AuthType = any>(action: IOIDCLoginAction): Generator<any, AuthType, any> {
  try {

    getOIDCConfig().loggerAPI.debug("oidcLoginSaga: action", action)

    yield put(getOIDCConfig().requestStateAPI.taskStartedAction(OIDCRequestScopes.LoginWithOIDCToken))

    const platformAuthReply: AuthType = yield call(
      getOIDCConfig().oidcAPI.oidcLogin,
      action.provider.shortName,
      action.idToken,
      action.accessToken,
      action.termsAccepted
    )

    getOIDCConfig().loggerAPI.debug("oidcLoginSaga: platformAuthReply", platformAuthReply)

    yield put(setPlatformAuthReplyAction(platformAuthReply))

    yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoginWithOIDCToken))

    // We won't need the stored items no more.
    // NOTE this could also be done in an onSuccess-callback provided via action (e.g. from the calling hook),
    // or maybe by using some requestState-interpretation (also in the calling hook).
    clearOIDCItemsFromStorage()

    if (action.handler?.onSuccess) {
      action.handler.onSuccess()
    }

    return platformAuthReply
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : getOIDCConfig().getUnknownErrorCode()
    getOIDCConfig().handleSagaError("oidcLoginSaga", errorMessage, null, err)
    getOIDCConfig().loggerAPI.debug("oidcLoginSaga: errorMessage", errorMessage)


    // @todo oauth diesen Spezialfall (a) sauber implementieren und (b) dokumentieren.
    // (In branch-info als "Mögliche Änderungen - prüfen ob sinnvoll" festgehalten mit Verweis hierher.)
    // Situation: Wir haben hier ein "ein Request braucht einen zweiten Anlauf mit mehr Daten inkl User-Eingabe, gilt aber durchgehend als loading".
    // Konkret: beim OIDC-Login kann das Backend den Fehler TERMS_ACCEPTANCE_REQUIRED_ERROR_MSG zurückgeben, den wir auch voll als
    // Teil des "sauberen" Ablaufs sehen, nämlich genau dann wenn der Nutzer noch nicht in der DB ist, aber wir backendseitig sagen,
    // dass die Terms explizit akzeptiert werden müssen. Da wir aber dem Nutzer nicht bei *jedem* Login die Aktzeptieren-Checkbox anzeigen wollen,
    // und wir auch nicht anders herausfinden können, ob der Nutzer schon in der DB ist, braucht dieser Request ggf. einen zweiten Anlauf.
    // Außerdem ist noch unklar: machen wir es wie jetzt per Callback-Action oder doch lieber per Nachricht im Stage? Ggf sogar RequestState erweitern?
    // Eine Erweiterung des RequestState könnte sinnvoll sein, wenn wir dieses "zwei-Anläufe-Prinzip" systematisieren wollen.

    // An dieser Stelle könnten folgende Fehler kommen, uns interessiert aber nur der 403 TERMS_ACCEPTANCE_REQUIRED_ERROR_MSG.
    // Alle anderen Fehler sind nicht "erwarteter" Teil des "zwei-Anläufe-Prinzip"s.
    // 500 = configuration error - bspw existiert kein oidcUserProvider-Eintrag für den Provider
    // 403 = CustomUserMessageAuthenticationException('Terms acceptance required.', [], 403
    // wenn termsAccepted !== undefined, aber nicht drin sein darf <- BadRequestHttpException('"termsAccepted" parameter is only allowed for new users & only when required!')
    // wenn userInfoEndpoint gesetzt aber accessToken == undefined <- BadRequestHttpException('Missing "accessToken" parameter.');
    // wenn OAuth-Provider userInfoEndpoint einen Fehler liefert <- HttpException(500, 'Failed to load userInfo.', $e);
    // wenn userInfoEndpoint *nicht* gesetzt ist aber idToken == undefined <- BadRequestHttpException('Missing "idToken" parameter.');

    // Spezielle Behandlung, wenn die Zustimmung zur AGB eingeholt werden muss bei erstmaligem Login eines Nutzers per OIDC (erstmaliges Account-Anlegen)
    // Aktuelle Umsetzung: wenn /dieser/ "erwartete Fehler" kommt,
    // - schreiben wir den requestError *nicht* in den State, da es zum "zwei-Anläufe-Prinzip" gehört und nicht ein Fehler ist, der dem User angezeigt werden soll
    // - rufen den Callback auf statt den Fehler über einen AppState rauszugeben
    if (errorMessage === TERMS_ACCEPTANCE_REQUIRED_ERROR_MSG) {
      getOIDCConfig().loggerAPI.debug("oidcLoginSaga: calling reactOnTermsAcceptanceRequired")
      action.reactOnTermsAcceptanceRequired()
      // ... aber müssen wir den Request trotzdem irgendwie "abschließen", damit er nicht mehr als "loading" gilt?
      // -> nein; zumindest nicht mit "taskFailed", da sonst die Page einen Fehler zeigt, obwohl das erwartetes Verhalten ist!
      // yield put(getOIDCConfig().requestStateAPI.taskSucceededAction(OIDCRequestScopes.LoginWithOIDCToken))

    } else
      // Spezielle Behandlung, wenn ein Account mit dieser Mailadresse bereits existiert
      // Aktuelle Umsetzung: wenn /dieser/ "erwartete Fehler" kommt,
      // - schreiben wir den requestError *nicht* in den State, da es zum "Accounts-Integrieren" gehört und nicht ein Fehler ist, der dem User angezeigt werden soll
      // - rufen den Callback auf statt den Fehler über einen AppState rauszugeben
      if (errorMessage.startsWith(EMAIL_EXISTS_ERROR_MSG_START)) {

        // gather required data
        const emailAddress = errorMessage.split("'")[1]
        const decodedToken: {
          sub: string
        } = jwtDecode(action.idToken)
        const externalIdentifier = decodedToken.sub

        // build messages
        const subject = "Proprietären Account mit SSO-Account verbinden"
        const message = "E-Mail-Adresse: " + emailAddress + "\n"
          + "Externer Identifier: " + externalIdentifier + "\n"
          + "Instanz: " + BASE_URL

        // @todo oauth We could send a BugReport, but action/saga/client-method are not yet implemented
        // so we let the user send the email manually.
        // const bugReport: IContactEmail = {
        //   name: "oidcLoginSaga",
        //   email: emailAddress,
        //   message: subject + "<br />\n" + message
        // }
        // yield put(sendContactProgramEmailAction(bugReport, null))

        const newErrorMessage = errorMessage + "<br /><br />"
          + "Es existiert bereits ein Account mit der E-Mail-Adresse '" + emailAddress + "'.<br />"
          + "Vermutlich möchtest Du nun auf eine Anmeldung über Deinen Single-Sign-On-Anbieter '" + action.provider.displayName + "' wechseln.<br />"
          + "Dafür ist es nötig, dass Du eine E-Mail mit den entsprechenden Angaben an technik@futureprojects.de sendest.<br /><br />"
          + "Du kannst <a href=\"mailto:technik@futureprojects.de?subject=" + subject + "&body=" + encodeURI(message) + "\">diesen Link</a> verwenden, "
          + "um ein Fenster zu öffnen, in dem die E-Mail schon vorausgefüllt ist, so dass Du sie nur abzusenden brauchst.<br />"
          + "Sollte das nicht funktionieren, kopiere bitte diesen Inhalt in eine E-Mail und sende sie an o.g. E-Mail-Adresse:<br />"
          + "<br /><br />"
          + "<pre>"
          + message
          + "</pre>"
          + "<br /><br />"
          // + "Es wurde bereits eine Email an den technischen Support gesendet, damit dieser Deinen Account migriert.<br />"
          + "Du wirst eine Email-Benachrichtigung erhalten, sobald der Prozess abgeschlossen ist."

        // Wir leiten in dem Fall wirklich zur Fehlerseite weiter, und zeigen die entsprechend angepasste Meldung.
        // Ein Zurückreichen zur aufrufenden Page und das dortige Anzeigen eines Modals mit den relevanten Angaben wäre nett,
        // ist aber unnötig für den seltenen/einmaligen Fall
        yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoginWithOIDCToken, newErrorMessage))

      } else {
        yield put(getOIDCConfig().requestStateAPI.taskFailedAction(OIDCRequestScopes.LoginWithOIDCToken, errorMessage))
      }

    yield put(setPlatformAuthReplyAction(null))
    return null
  }
}

// #endregion