import { runsInBrowser } from "@basics/util-importless"

// these functions don't do anything special, they are used in the codebase for simple mocking
// NOTE: localStorage is a browser function so building fails without browser check

export const getStorageItem = (key: string): string =>
  runsInBrowser() ? localStorage.getItem(key) : ""

export const removeStorageItem = (key: string): void =>
  runsInBrowser() && localStorage.removeItem(key)

export const setStorageItem = (key: string, value: string): void =>
  runsInBrowser() && localStorage.setItem(key, value)