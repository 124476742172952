import { IModel, INumericIdentifierModel, IRI } from "@api/schema"
import { stringAsHtmlId } from "@basics/util-importless"
import { LOCALE_TO_BCP47 } from "@locales/locales-config"
import { FCP_API_ENTRYPOINT, LANGUAGES_AVAILABLE } from "config"

/**
 * checks, if a given IModel is loaded with detailed results
 *
 * @param entity the entity to check
 * @returns true, if detailed
 *
 * NOTE: does not work, when IProject is fetched within a collection, e.g. within a list of currentUser or similar -> @todo FCP-1062
 */
export const isDetailedObject = (entity: IModel): boolean => !!entity && entity.detailResult

// @todo this is for old APIs that dont prefix their our themselves,
// to be removed when no old API is used anymore, search for all usages of this function
export const prefixApiUrl = (path: string): string =>
  path.startsWith('https://') || path.startsWith('http://') ? path : FCP_API_ENTRYPOINT + path

/**
 * Reduces an array of INumeric entities to a number array, just of ids of those objects
 *
 * @param list An array of INumericIdentifierModel with an id-attribute.
 * @returns an array of ids only
 */
export const getIDsByNumericIdentifierModel = (list: INumericIdentifierModel[]): number[] =>
  list?.map((e) => e.id) || []

/**
 * Checks if a given ID is included in the list of the items given in the array
 *
 * @param id id of an entity
 * @param listOfEntities array of entities
 * @returns true, if at least one of the given entities has the given id
 */
export const idExistsInEntities = (id: number, listOfEntities: INumericIdentifierModel[]): boolean => {
  if (!id || !listOfEntities) {
    return false
  }
  return listOfEntities.find(entity => entity.id === id) !== undefined
}

/**
 * converts an IRI or an IModel to a string useable as id of an HTML element
 *
 * e.g. /projects/8 becomes projects_8 to be attached as id={elementId(entity)}
 * to be identified as target (e.g. for tooltips) or in tests and to have an anchor for scrolling
 * to the searched HTML element
 *
 * NOTE that HTML element ids MUST be unique per HTML page. Users of this method must ensure that no two components
 * use this method on the same page; or that there is one main component that uses the generated id as-is and other
 * components add component-specific sub-strings to the generated id.
 *
 * @param entity an entity as IModel or IRI
 * @returns a converted and useable html id based on the entity IRI
 *
 * @todo make sure, html id is always a (unique) string: FCP-1656
 */
export const iriAsHtmlId = (entity: IModel | IRI): string => {
  if (!entity) {
    return undefined
  }

  return stringAsHtmlId(typeof entity === "string" ? entity : entity["@id"])
}

/**
 * @param bcp47 a bcp47 code
 * @returns the corresponding locales key of the given bcp47 code
 *
 * @see locales-config.js
 */
export const bcp47ToLocale = (bcp47: string): string =>
  Object.keys(LOCALE_TO_BCP47).find(
    key => LOCALE_TO_BCP47[key] === bcp47
  )

export const getEffectiveLanguages = (supportedLocales: string[]): string[] => {
  return supportedLocales.filter(lang => LANGUAGES_AVAILABLE.includes(lang))
}