import * as Popper from "@popperjs/core"
import { MutableRefObject, ReactElement, useEffect, useRef, useState } from "react"
import { Tooltip } from "reactstrap"

import { useToggleState } from './hooks/useToggleState'


/**
 * returned values of this useTooltip hook
 */
interface TooltipHook {
  tooltipOpen: boolean
  /**
   * reference to be attached to the Element, e.g.
   * <a ref={tooltipTargetRef} className={finalClassName}>{contentBoxWithTooltip}</a>
   */
  tooltipTargetRef: MutableRefObject<any>
  /**
   * Tooltip to be inserted into the code near the referenced object.
   * Is null if no tooltiptext is given, so no Tooltip is shown with empty text.
   */
  ToolTip: ReactElement
}

interface useTooltipProps {
  /** styles for the inner content of the tooltip */
  innerClassName?: string
  /**
   * may be "click" | "hover" | "focus" or combinations
   * default: "hover focus"
   *
   * @see https://getbootstrap.com/docs/5.0/components/tooltips/#options
   */
  trigger?: string
  /** placement of the tooltip. Default: "top" */
  placement?: Popper.Placement
}

/**
 * Hook for using a tooltip.
 *
 * NOTE: when attaching such a tooltip to functional components (e.g. <Button>) do not attach the tooltipTargetRef
 * to ref but to innerRef or similar
 *
 * @param tooltipText text of the tooltip. Returned ToolTip element is undefined, if no tooltiptext exists
 * @return a target ref to be pinned onto the element that should react on mouseover, focus or click and to which is the tooltip will be attached
 */
export const useTooltip = (
  /**
   * text to be shown as tooltip
   */
  tooltipText: string,
  /** optional props to specify the tooltip */
  props: useTooltipProps = {
    trigger: "hover focus",
    placement: "top"
  }
): TooltipHook => {

  const { isTrue: tooltipOpen, toggle: toggleTooltip } = useToggleState(false)

  // Hint of the official documentation: https://reactjs.org/docs/hooks-reference.html#useref
  /**
   * Keep in mind that useRef doesn’t notify you when its content changes.
   * Mutating the .current property doesn’t cause a re-render.
   * If you want to run some code when React attaches or detaches a ref to a DOM node,
   * you may want to use a callback ref instead.
   */
  // e.g the tooltip should be display when the targets are initialized, that mean's if the target reference is initialized
  // if the tooltipTargetRef is still null, the tooltip will not be rendered
  // Be aware: the tooltipTargetRef can be intialized before the first render, but there is no guarante
  //
  // solution: adding a useState und a useEffect, which has the tooltipTargetRef in the dependency array
  // the callback idea is not suitable in this case with making sure to display the tooltip, cause with the callback, there is e.g. the possiblity to do e.g. manipulation on the target or focus,
  // but to display the tooltip, a rerender is needed, therefor a useState and a useEffect is the suitable solution
  const [targetRefInitialized, setTargetRefInitialized] = useState(false)
  // reference to to the tooltip target, initially null => needed to make sure, that the tooltip is rendered after the tooltip targets are mounted
  const tooltipTargetRef = useRef(null)

  useEffect(() => {
    if (tooltipTargetRef?.current) {
      setTargetRefInitialized(true)
    }
  }, [tooltipTargetRef])


  // tooltip is only returned if it is
  // bound to a target
  // which is initialized
  // and a tooltip text is given
  const ToolTip = tooltipTargetRef?.current && targetRefInitialized && tooltipText &&
    <Tooltip
      innerClassName={props.innerClassName}
      isOpen={tooltipOpen}
      target={tooltipTargetRef?.current}
      toggle={toggleTooltip}
      trigger={props.trigger}
      placement={props.placement}
    >
      {tooltipText}
    </Tooltip>

  return { tooltipTargetRef, ToolTip, tooltipOpen }
}