import { Action } from "redux"

import { IHydraCollection } from "@modules/backend-definitions/src/models/hydra"

import { IOIDCState } from "./definitions"
import { getOIDCConfig } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"

// #region reducer actions

/**
 * List of available reducer action types.
 */
enum OIDCReducerActionTypes {
  SetOIDCProviders = "SET_OIDC_PROVIDERS",
  SetOIDCTokens = "SET_OIDC_TOKENS",
  SetPlatformAuthReply = "SET_PLATFORM_AUTH_REPLY"
}

/**
 * Abstract base interface of all OICD reducer actions.
 */
interface IOIDCReducerAction extends Action<OIDCReducerActionTypes> {
  type: OIDCReducerActionTypes
}

interface ISetOIDCProvidersAction extends IOIDCReducerAction {
  oidcProviders: IHydraCollection<IOIDCProvider>
  type: OIDCReducerActionTypes.SetOIDCProviders
}

interface ISetOIDCTokensAction extends IOIDCReducerAction {
  idToken: string
  accessToken: string
  type: OIDCReducerActionTypes.SetOIDCTokens
}

interface ISetPlatformAuthReplyAction<AuthType = any> extends IOIDCReducerAction {
  platformAuthReply: AuthType
  type: OIDCReducerActionTypes.SetPlatformAuthReply
}

/**
 * Creates an action to store the loaded OIDC provider information.
 */
export const setOIDCProvidersAction = (oidcProviders: IHydraCollection<IOIDCProvider>): ISetOIDCProvidersAction => ({
  oidcProviders,
  type: OIDCReducerActionTypes.SetOIDCProviders
})

/**
 * Creates an action to store the fetched OIDC token.
 */
export const setOIDCTokensAction = (idToken: string, accessToken: string): ISetOIDCTokensAction => ({
  idToken,
  accessToken,
  type: OIDCReducerActionTypes.SetOIDCTokens
})

/**
 * Creates an action to store the host application backend's auth reply.
 */
export const setPlatformAuthReplyAction = <AuthType = any>(platformAuthReply: AuthType): ISetPlatformAuthReplyAction => ({
  platformAuthReply,
  type: OIDCReducerActionTypes.SetPlatformAuthReply
})

// #endregion

// #region reducer

/**
 * Initial value of the OICD provider list.
 *
 * @todo oauth verify that `[]` is the best of all possible values (compared to `undefined` and others)
 * @todo oauth remove "export" if no longer used elsewhere
 */
export const OIDC_PROVIDER_DEFAULT_STATE_VALUE: IOIDCProvider[] = []

/**
 * Inital OICD module's state content in the host application's AppState.
 */
const initialOIDCState: IOIDCState = {
  oidcProviders: OIDC_PROVIDER_DEFAULT_STATE_VALUE,
  idToken: undefined,
  accessToken: undefined,
  platformAuthReply: undefined
}

/**
 * Reducer for the OICD state.
 */
export const oidcReducer =
  (state: IOIDCState = initialOIDCState, action: IOIDCReducerAction): IOIDCState => {
    switch (action.type) {
      case OIDCReducerActionTypes.SetOIDCProviders:
        const newCollection: IOIDCProvider[] = []
        // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
        const data = (action as ISetOIDCProvidersAction).oidcProviders
        data["hydra:member"].forEach((provider: IOIDCProvider) => {
          newCollection.push(provider)
        })
        getOIDCConfig().loggerAPI.debug("oidcReducer, SetOIDCProviders", newCollection)
        return {
          ...state,
          oidcProviders: newCollection
        }

      case OIDCReducerActionTypes.SetOIDCTokens:
        getOIDCConfig().loggerAPI.debug("oidcReducer, SetOIDCTokens", (action as ISetOIDCTokensAction).idToken, (action as ISetOIDCTokensAction).accessToken)
        return {
          ...state,
          // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
          idToken: (action as ISetOIDCTokensAction).idToken,
          accessToken: (action as ISetOIDCTokensAction).accessToken
        }

      case OIDCReducerActionTypes.SetPlatformAuthReply:
        getOIDCConfig().loggerAPI.debug("oidcReducer, SetPlatformAuthReply", (action as ISetPlatformAuthReplyAction).platformAuthReply)
        return {
          ...state,
          // @todo oauth: why doesn't typescript infer the correct type of action, like it does in the main app?
          platformAuthReply: (action as ISetPlatformAuthReplyAction).platformAuthReply
        }

      default:
        return state
    }
  }

// #endregion