import { Action } from "redux"

import { IUser } from "@api/schema"
import { IUserWriteDTO } from "@api/schema-dto"
import { IFormikActions } from "@redux/helper/actions"

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum RegistrationUsecases {
  RegisterUser = "REGISTER_USER",
  /**
   * processOnboardingDataBeforeRegistration - triggers "finalization of onboarding process", chores to do before registration
   */
  ProcessOnboardingDataBeforeRegistration = "_message_process_onboarding_data_before_registration",
  /**
   * processOnboardingDataAfterRegistration - triggers "finalization of onboarding process", chores to do after registration
   */
  ProcessOnboardingDataAfterRegistration = "_message_process_onboarding_data_after_registration",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IRegistrationAction extends Action {
  type: RegistrationUsecases
}

export interface IRegisterUserAction extends IRegistrationAction {
  actions: IFormikActions
  type: RegistrationUsecases.RegisterUser
  user: IUser
}

export interface IProcessOnboardingDataBeforeRegistrationAction extends IRegistrationAction {
  type: RegistrationUsecases.ProcessOnboardingDataBeforeRegistration
  userWriteDTO: IUserWriteDTO
}

export interface IProcessOnboardingDataAfterRegistrationAction extends IRegistrationAction {
  type: RegistrationUsecases.ProcessOnboardingDataAfterRegistration
  user: IUser
}

export type RegistrationActions =
  | IRegisterUserAction
  | IProcessOnboardingDataBeforeRegistrationAction
  | IProcessOnboardingDataAfterRegistrationAction

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const registerUserAction = (user: IUser, actions: IFormikActions): IRegisterUserAction => ({
  actions,
  type: RegistrationUsecases.RegisterUser,
  user,
})

/**
 * A global signal that user registration is upcoming.
 *
 * @param userWriteDTO The object containing the new user's registration details
 * @returns
 */
export const processOnboardingDataBeforeRegistrationAction = (userWriteDTO: IUserWriteDTO): IProcessOnboardingDataBeforeRegistrationAction => ({
  type: RegistrationUsecases.ProcessOnboardingDataBeforeRegistration,
  userWriteDTO,
})


export const processOnboardingDataAfterRegistrationAction = (user: IUser): IProcessOnboardingDataAfterRegistrationAction => ({
  type: RegistrationUsecases.ProcessOnboardingDataAfterRegistration,
  user,
})
