// This file exports all contents of the module.
// Using this approach, code that uses content of this module only imports `oidc/src`
// instead of specific files - allowing the module to change its internal structure
// w/o the necessity of adapting import statements in other code.
// TODO remove stuff that is not needed outside; see `../todo.md`


// The part of the host application backend API that is required by the module.
export * from './models/IOIDCAPI'
// The model definition (defined in host application backend API).
export * from './models/IOIDCProvider'
// Module's custom request state names.
export * from './models/request-states'

// Hook for logging in at host application backend.
export * from './react/useLoginWithOIDCToken'
// Hook for loading the list of OIDC providers from the host application backend.
export * from './react/useOIDCProviders'
// Hook for requesting the OIDC token from an OIDC provider.
export * from './react/useOIDCToken'

// Module's redux definitions.
export * from './redux/definitions'
// Module's state reducer.
export * from './redux/reducer'
// Module's sagas.
export * from './redux/saga'

// Utils
export * from './utils/util'

// Module's configuration object.
export * from './config'