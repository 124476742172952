import { VerificationActionTypes, VerificationActions } from "@redux/actions/verification"
import { IUsecaseRequestSuccessAction, REQUEST_PREFIX, SUCCESS_SUFFIX } from "@redux/helper/actions"
import { ScopeTypes } from "@redux/reduxTypes"

/**
 * All possible ActionTypes for a success request for all ScopeTypes
 */
type VerificationOperationRequestTypeSuccess = `${typeof REQUEST_PREFIX}${Uppercase<ScopeTypes.VerificationOperation>}${typeof SUCCESS_SUFFIX}`

/** reduces the state to a boolean, if a verification-operation was successful (e.g. verification of account, mail address change e.g.) */
export const verificationsReducer = (state = false, action: VerificationActions | IUsecaseRequestSuccessAction<VerificationOperationRequestTypeSuccess>): boolean => {
  switch (action.type) {
    case VerificationActionTypes.VerificationOperationSuccess:
      return (action as IUsecaseRequestSuccessAction).result as boolean

    case VerificationActionTypes.ResetVerificationResult:
      return false

    default:
      return state
  }
}
