import { Action } from "redux-saga"

import { IIdea, IProjectCreation, IProjectMembership, IProjectFollowership } from "@api/schema"

import { OnboardingUsecases } from "./definitions"

export interface IOnboardingStateAction extends Action {
  // NOTE should only contain "state" usecases that are not "usecases" at all b/c they are never directly triggered from user (interaction)
  type: OnboardingUsecases
}

export interface IAddNewIdeaOnboardingAction extends IOnboardingStateAction {
  idea: IIdea
  type: OnboardingUsecases.AddNewIdea
}

export const addNewIdeaOnboardingAction = (idea: IIdea): IAddNewIdeaOnboardingAction => ({
  idea,
  type: OnboardingUsecases.AddNewIdea,
})

export interface IAddNewProjectFromIdeaOnboardingAction extends IOnboardingStateAction {
  project: IProjectCreation
  type: OnboardingUsecases.AddNewProjectFromIdea
}

export const addNewProjectFromIdeaOnboardingAction = (project: IProjectCreation): IAddNewProjectFromIdeaOnboardingAction => ({
  project,
  type: OnboardingUsecases.AddNewProjectFromIdea,
})

/**
 * @todo multi refactor Onboarding, considering following ticket:
 * @see https://futureprojects.atlassian.net/browse/FCP-1616
 */
export interface IAddNewProjectMemberApplicationOnboardingAction extends IOnboardingStateAction {
  projectMembership: IProjectMembership
  type: OnboardingUsecases.AddNewProjectMemberApplication
}

export const addNewProjectMemberApplicationOnboardingAction = (memberApplication: IProjectMembership): IAddNewProjectMemberApplicationOnboardingAction => ({
  projectMembership: memberApplication,
  type: OnboardingUsecases.AddNewProjectMemberApplication,
})

export interface IAddNewProjectFollowershipOnboardingAction extends IOnboardingStateAction {
  projectFollowership: IProjectFollowership
  type: OnboardingUsecases.AddNewProjectFollowership
}

export const addNewProjectFollowershipOnboardingAction = (projectFollowership: IProjectFollowership): IAddNewProjectFollowershipOnboardingAction => ({
  projectFollowership,
  type: OnboardingUsecases.AddNewProjectFollowership,
})

export interface IResetOnboardingStateAction extends IOnboardingStateAction {
  type: OnboardingUsecases.ResetOnboardingData
}

export const resetOnboardingStateAction = (): IResetOnboardingStateAction => ({
  type: OnboardingUsecases.ResetOnboardingData,
})