import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IRequestState } from "@modules/frontend-definitions/src"

import { getOIDCConfig } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"
import { OIDCRequestScopes } from "../models/request-states"
import { OIDC_PROVIDER_DEFAULT_STATE_VALUE } from "../redux/reducer"
import { loadOIDCProvidersAction } from "../redux/saga"


/**
 * Options to define details of the work mode of the `useOIDCProviders` hook.
 */
type UseOIDCProvidersOptions = {
  doNotLoad?: boolean
}

/**
 * Return type of the `useOIDCProviders` hook.
 */
export type OIDCProvidersResult = {
  providers: IOIDCProvider[]
  request: IRequestState
}

/**
 * Hook to load supported OICD providers from the host application backend.
 *
 * @param options Options for the loading process.
 */
export const useOIDCProviders = (options?: UseOIDCProvidersOptions): OIDCProvidersResult => {
  const dispatch = useDispatch()

  const providers = useSelector(getOIDCConfig().selectOIDCState).oidcProviders
  const request = useSelector(getOIDCConfig().requestStateAPI.selectRequestState(OIDCRequestScopes.LoadOIDCProviders))

  useEffect(() => {
    getOIDCConfig().loggerAPI.debug("useOIDCProviders: useEffect", providers, options, request)
    if (
      // block 1: output data not yet fetched
      providers === OIDC_PROVIDER_DEFAULT_STATE_VALUE // providers have not been loaded
      // block 1.b: output data should be fetched
      && !options?.doNotLoad
      // block 2: input data is available
      // NOTE: no input data required
      // block 3: request state is ok
      && (!request || !request.loaded)
      && !request.isLoading
      && !request.loadingError
    ) {
      getOIDCConfig().loggerAPI.debug("useOIDCProviders: dispatch loadOIDCProvidersAction")
      dispatch(loadOIDCProvidersAction())
    }
  }, [
    JSON.stringify(providers),
    JSON.stringify(options),
    JSON.stringify(request)
  ])

  return {
    providers,
    request
  }
}
