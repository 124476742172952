import hasProp from "lodash/has"
import { useEffect, useState } from 'react'

import FAQEntryLayer from './FAQEntryLayer'
import { FAQHelper, FAQKeys, FAQLayer, FAQType } from './FAQHelper'
import FAQTopicCard from './FAQTopicCard'


interface IProps {
  faqNode: FAQType
  faqKey: string | undefined
}

/**
 *
 * The TopicLayer is the second layer after the AreaLayer, which will appear, when user selected an area.
 * If the FAQKey contains an area and a topic. The card with the topic will be open.
 * E.g.,if the user come's back from the EntryLayer, means, it comes from an entry within a certain topic. Therfor the
 * Card with the topic will be open.
 *
 * It is called from the AreaLayer (upper layer).
 * The TopicLayer calls the next layer (EntryLayer)
 */

const FAQTopicLayer = ({ faqNode, faqKey }: IProps): JSX.Element => {

  const [areaName, setAreaName] = useState<string>()

  useEffect(() => {
    if (faqKey) {
      setAreaName(FAQHelper.getKeyPart(faqKey, 1))
    }
  }, [faqKey, faqNode])

  return (FAQHelper.layerToBeShown(faqKey) === FAQLayer.TopicLayer)
    ? <div className="faq__topic-layer">
      {// make sure the selected area within faqNode is really an object
        typeof faqNode[areaName] === "object" &&
        <div key={areaName}>
          {// show area title if given
            hasProp(faqNode[areaName], FAQKeys.Title) && <h2>{faqNode[areaName].title}</h2>
          }
          {// show every topic with all its questions, exclude title/picture
            Object.keys(faqNode[areaName])
              .filter(value => value !== FAQKeys.Title.valueOf() && value !== FAQKeys.Picture.valueOf())
              .map((key) => <FAQTopicCard
                key={key}
                topicTitle={faqNode[areaName][key].title}
                faqKey={areaName + "." + key}
                isOpen={FAQHelper.getKeyPart(faqKey, 2) === key} // open if the second part (= topic) of the faqKey matches the current key
                faqNode={faqNode[areaName][key]}
              />)
          }
        </div>
      }
    </div>
    : <FAQEntryLayer
      faqKey={faqKey}
      faqNode={faqNode}
    />
}

export default FAQTopicLayer

