interface IProps {
  className?: string
}

/**
 * Provides an alert element: a small symbol for signaling an "alert" to be attached to (action)icons or similar
 */
const AlertSymbol: React.FC<IProps> = (props: IProps) => {
  const { className = "" } = props

  return <span className={"news-alert-symbol " + className} />
}

export default AlertSymbol
