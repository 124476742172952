import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import client from "@api/client"
import { entityTypeFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { IModel } from "@api/schema"
import { newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, updateModelSuccessAction } from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { SubmissionError } from "@services/submissionError"

import { ITransitionAction, TransitionUsecases } from "./definitions"

/*
 * @todo multi refactor transition saga? Could include locking and unlocking
 * => for details @see https://futureprojects.atlassian.net/browse/FCP-1553
 */

/* @†odo multi maybe move to generalSaga */


// #region generic saga
export function* transitionWatcherSaga(): any {
  yield all([
    takeEvery(TransitionUsecases.TransitionEntity, withCallback(entityTransitionSaga)),
  ])
}

function* entityTransitionSaga<E extends IModel, Transition>(action: ITransitionAction<E, Transition>) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type

  const entityType = entityTypeFromIModelOrIRI(action.entity)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    const entity: E = yield call(client.transitionEntity, action.entity, action.transition)

    yield put(updateModelSuccessAction(entityType, entity))

    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, entity))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, entity)
    }

    return entity
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("transitionSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
// #endregion