import { Action } from "redux"

/*
Actions for creating a new project idea
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

export enum IdeaActionTypes {
  CreateIdea = "CREATE_IDEA",
  TriggerNotificationForUnusedIdea = "TRIGGER_NOTIFICATION_FOR_UNUSED_IDEA"
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IIdeaAction extends Action {
  type: IdeaActionTypes
}

/**
 * Action should be triggered when an idea of a user is unused,
 * means: no project was created based on this idea yet.
 */
export interface ITriggerNotificationForUnusedIdeaAction extends IIdeaAction {
  type: IdeaActionTypes.TriggerNotificationForUnusedIdea
}

export type IdeaActions = ITriggerNotificationForUnusedIdeaAction

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const triggerNotificationForUnusedIdeaAction = (): ITriggerNotificationForUnusedIdeaAction => ({
  type: IdeaActionTypes.TriggerNotificationForUnusedIdea
})
