import { useState } from "react"


// #region useToggleState

/** type of what useToggleState hook returns */
interface UseToggleStateType {
  isTrue: boolean
  setIsTrue: (value: boolean) => void
  toggle: () => void
}

/**
 * A hook for toggling a boolean state.
 * Usage: const { isTrue, setIsTrue, toggle } = useToggleState(false)
 * Use for toggling tooltips, cards etc.
 *
 * @param initialState the initial state of the toggler
 * @returns isTrue as boolean, setIsTrue as function to set the state, toggle as function to inverse the current state
 */
export const useToggleState = (initialState: boolean): UseToggleStateType => {
  const [isTrue, setIsTrue] = useState(!!initialState)
  const toggle = () => setIsTrue(!isTrue)

  return { isTrue, setIsTrue, toggle }
}
// #endregion useToggleState