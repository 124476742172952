/**
 * defines accepted types for order-parameters by the API
 */
export enum OrderSpin {
  Asc = "asc",
  Desc = "desc"
}

export interface ISortCriteria<SortByParam = string> {
  sortBy?: SortByParam
  sortOrder?: OrderSpin
}