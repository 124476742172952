export interface ISubmissionErrorList {
  [key: string]: string
}

export interface INestedErrorList {
  [key: string]: string | ISubmissionErrorList | ISubmissionErrorList[] | INestedErrorList
}

export class SubmissionError extends Error {
  public errors: ISubmissionErrorList

  constructor(errors: ISubmissionErrorList) {
    super("failure.submissionFailed")

    if (errors && Object.keys(errors).length > 0) {
      this.message = "failure.submissionFailedWithFormErrors"
    }

    this.errors = errors
    // Error.captureStackTrace(this, this.constructor)
    this.name = this.constructor.name

    return this
  }
}

/**
 * Flattens a nested array of errors by combining the keys and prepending the prefix.
 * If a numeric key is found it is ignored (for array properties).
 * Used to display form errors that have no direct form elements to be placed at.
 *
 * flattenErrors({workPackages: [0: {name: "error"}]}, "project") ->
 * {"project.workPackages.name": "error"}
 *
 * @param errors (nested) error set
 * @param prefix string
 */
export const flattenErrors = (errors: INestedErrorList, prefix = ""): ISubmissionErrorList => {
  let flattened: ISubmissionErrorList = {}
  Object.keys(errors).forEach((k) => {
    if (typeof errors[k] !== "string") {
      const newPrefix: string = /^\d+$/.exec(k)
        ? prefix
        : prefix ? prefix + "." + k : k

      flattened = {
        ...flattened,
        ...flattenErrors(errors[k] as INestedErrorList, newPrefix)
      }
    } else {
      flattened[prefix ? prefix + ((/\d*/.exec(k)).length === 1 ? "" : "." + k) : k] = errors[k]
    }
  })

  return flattened
}