import { IProject, IProjectCreation, IProjectMembership, IProjectFollowership } from "@api/schema"

import {
  IAddNewIdeaOnboardingAction,
  IAddNewProjectFromIdeaOnboardingAction,
  IAddNewProjectMemberApplicationOnboardingAction,
  IAddNewProjectFollowershipOnboardingAction,
  IOnboardingStateAction
} from "./actions"
import { OnboardingUsecases } from "./definitions"

export interface IOnboardingState {
  newIdea: IProject
  newProjectFromIdea: IProjectCreation
  newProjectMemberApplication: IProjectMembership
  newProjectFollowership: IProjectFollowership
}

// export for tests
export const emptyOnboardingState: IOnboardingState = {
  newIdea: null,
  newProjectFromIdea: null,
  newProjectMemberApplication: null,
  newProjectFollowership: null,
}

export const onboardingReducer =
  (state: IOnboardingState = emptyOnboardingState, action: IOnboardingStateAction): IOnboardingState => {
    switch (action.type) {
      case OnboardingUsecases.AddNewIdea:
        const ideaAction = action as IAddNewIdeaOnboardingAction
        return {
          ...state,
          newIdea: ideaAction.idea
        }

      case OnboardingUsecases.AddNewProjectFromIdea:
        const projectAction = action as IAddNewProjectFromIdeaOnboardingAction
        return {
          ...state,
          newProjectFromIdea: projectAction.project
        }

      case OnboardingUsecases.AddNewProjectMemberApplication:
        const memberApplicationAction = action as IAddNewProjectMemberApplicationOnboardingAction
        return {
          ...state,
          newProjectMemberApplication: memberApplicationAction.projectMembership
        }

      case OnboardingUsecases.AddNewProjectFollowership:
        const followershipAction = action as IAddNewProjectFollowershipOnboardingAction
        return {
          ...state,
          newProjectFollowership: followershipAction.projectFollowership
        }

      case OnboardingUsecases.ResetOnboardingData:
        return { ...emptyOnboardingState }

      default:
        return state
    }
  }