import { Action } from "redux"

import { AuthActionTypes, IJwtState } from "@basics/auth"

/*
This file contains all actions and needed enums and interfaces, regarding the Authentification of a user.
*/

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * Basic Action for Authentication-Actions
 */
export interface IAuthAction extends Action {
  type: AuthActionTypes
}


export interface IInitAuthAction extends IAuthAction {
  type: AuthActionTypes.InitAuth
}


export interface IRefreshTokenAction extends IAuthAction {
  type: AuthActionTypes.RefreshToken
}

export interface ISetJwtAction extends IAuthAction {
  state: IJwtState
  type: AuthActionTypes.SetJwt
}

export interface IUserIsIdleAction extends IAuthAction {
  type: AuthActionTypes.UserIsIdle
}


/**
 * Interface to combine all Actions
 */
export type AuthActions =
  // IForgotPasswordAction
  | IInitAuthAction
  // | ILoginAction
  // | ILogoutAction
  | ISetJwtAction
  | IRefreshTokenAction
  | IUserIsIdleAction

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */


export const initAuthAction = (): IInitAuthAction => ({
  type: AuthActionTypes.InitAuth,
})

export const refreshTokenAction = (): IRefreshTokenAction => ({
  type: AuthActionTypes.RefreshToken,
})

export const setJwtAction = (state: IJwtState): ISetJwtAction => ({
  state,
  type: AuthActionTypes.SetJwt,
})

export const userIsIdleAction = (): IUserIsIdleAction => ({
  type: AuthActionTypes.UserIsIdle,
})
