import { Action } from "redux"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { ChallengeTransitionState, IChallenge } from "@api/schema"
import { IFormikActions, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, updateModelSuccessAction } from "@redux/helper/actions"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { AppState } from "@redux/reducer"
import { selectCollection } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"
import { SubmissionError } from "@services/submissionError"


/** *************************************************************************************************
 * This enum defines the usecases around the "challenges management".
 */
enum ChallengesForManagementUsecases {
  /**
   * transition challenge
   */
  TransitionChallenge = "_usecase_transition_challenge",
}


// *************************************************************************************************
// #region challenge specific selectors

export const selectChallengeByConcretizationId = (state: AppState, id: number): IChallenge =>
  selectCollection<IChallenge>(state, EntityType.Challenge)
    .filter((f) => f.concretizations.filter((c) => c.id === id).length === 1)
    .shift()

export const selectChallengeByAttachmentDefinitionId = (state: AppState, id: number): IChallenge =>
  selectCollection<IChallenge>(state, EntityType.Challenge)
    .filter((f) => f.attachmentDefinitions.filter((c) => c.id === id).length === 1)
    .shift()


// #endregion


// *************************************************************************************************
// #region transition challenge

interface ITransitionChallengeAction extends Action {
  actions: IFormikActions
  challenge: IChallenge
  transition: ChallengeTransitionState
  type: ChallengesForManagementUsecases.TransitionChallenge
}

export const transitionChallengeAction = (challenge: IChallenge, transition: ChallengeTransitionState, actions: IFormikActions): ITransitionChallengeAction => ({
  actions,
  challenge,
  transition,
  type: ChallengesForManagementUsecases.TransitionChallenge,
})

export function* challengesWatcherSaga(): any {
  yield all([
    takeEvery(ChallengesForManagementUsecases.TransitionChallenge, withCallback(transitionChallengeSaga)),
  ])
}

function* transitionChallengeSaga(action: ITransitionChallengeAction) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type // ChallengesForManagementUsecases.TransitionChallenge

  try {
    // yield put(usecaseRequestRunningAction(ScopeTypes.ChallengeOperation))
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Challenge, usecaseKey))

    const challenge: IChallenge = yield call(apiClient.transitionChallenge, action.challenge, action.transition)

    yield put(updateModelSuccessAction(EntityType.Challenge, challenge))

    // yield put(usecaseRequestSuccessAction(ScopeTypes.ChallengeOperation, challenge))
    // yield put(usecaseRequestSuccessAction(usecaseKey, challenge))
    yield put(newSingleEntityUsecaseRequestSuccessAction(EntityType.Challenge, usecaseKey, challenge))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, challenge)
    }

    return challenge
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    // yield put(usecaseRequestRunningAction(ScopeTypes.ChallengeOperation, errorMessage))
    yield put(newSingleEntityUsecaseRequestRunningAction(EntityType.Challenge, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

// #endregion