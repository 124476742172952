import hasProp from "lodash/has"
import { all, call, put, select, takeEvery } from "redux-saga/effects"
import { putWait, withCallback } from "redux-saga-callback"

import apiClient from "@api/client"
import { iriFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import {
  AbstractProjectUserRelation,
  IAttachmentDefinition,
  IChallengeConcretization,
  IDiscussion,
  IFeedbackPost,
  IHydraCollection,
  IModel,
  INumericIdentifierModel,
  IProject,
  IProposal,
  IProposalAttachment,
  ITeamUpload,
  IUser
} from "@api/schema"
import { IStatistics } from "@api/schema/statistics"
import { transformEntityToWriteDTO } from "@api/schema-dto"
import { idFromIModelOrIRI } from "@basics/util-importless"
import {
  COLLECTION_LOADING,
  ILoadCollectionByAction,
  ILoadCollectionPageAction,
  ILoadSingleEntityByAction,
  IModelCreateOperationInvokingAction,
  IModelDeleteOperationInvokingAction,
  IModelUpdateOperationInvokingAction,
  IStatisticsApiRequestInvokingAction,
  IUploadApiRequestInvokingAction,
  LOADING,
  SLUG_OR_ID,
  createModelSuccessAction,
  deleteModelSuccessAction,
  loadCollectionSuccessAction,
  loadModelAction,
  loadModelSuccessAction,
  newFilteredCollectionUsecaseRequestRunningAction,
  newFilteredCollectionUsecaseRequestSuccessAction,
  newLoadCollectionAction,
  newSingleEntityUsecaseRequestRunningAction,
  newSingleEntityUsecaseRequestSuccessAction,
  statisticsRequestRunningAction,
  statisticsRequestSuccessAction,
  updateModelSuccessAction,
  usecaseRequestRunningAction,
  usecaseRequestSuccessAction
} from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { selectCurrentUser, selectCurrentUserId } from "@redux/reducer/auth"
import { selectMyFollowerships, selectMyMemberships, selectProjectByProposalIri } from "@redux/reducer/myProjects"
import { ActionTypes, EntityType } from "@redux/reduxTypes"
import { selectChallengeByAttachmentDefinitionId, selectChallengeByConcretizationId } from "@redux/usecases/challengesForManagement"
import { loadCurrentUserAction } from "@redux/usecases/userAccount/actions"
import { usecaseKeyForLoadCollection } from "@services/hooks/useEntityCollection"
import { SubmissionError } from "@services/submissionError"
import { isEntityTypeUserObjectRole } from "@services/userObjectRolesHelper"

// this file contains generic sagas


/**
 * bundling all single sagas to be registered in ./index.ts
 *
 * Mapping Entity-specific Actions onto sagas, which call the Entity-specific API-endpoints
 */
export function* generalWatcherSaga(): any {
  yield all([
    // takeEvery(MyProjectsActionTypes.LoadMyProjects, withCallback(loadEntityCollectionSaga)),
    takeEvery(ActionTypes.Create, withCallback(createEntitySaga)),
    takeEvery(ActionTypes.Update, withCallback(updateEntitySaga)),
    takeEvery(ActionTypes.Delete, withCallback(deleteEntitySaga)),
    takeEvery(ActionTypes.Upload, withCallback(uploadFileSaga)),
    takeEvery(ActionTypes.Load, withCallback(loadSingleEntitySaga)),
    takeEvery(ActionTypes.LoadCollection, withCallback(loadEntityCollectionSaga)),
    takeEvery(ActionTypes.LoadCollectionPage, withCallback(loadEntityCollectionPageSaga)),
    takeEvery(ActionTypes.LoadStatistics, withCallback(loadStatisticsSaga)),

    // @todo: loadModelAction müßte ein eigenes type kriegen, das so eindeutig ist, dass es hier gefangen werden kann:
    // takeEvery("LOAD_SINGLE_ENTITY", withCallback(loadSingleEntitySaga)),
    // takeEvery("LOAD_ENTITY_COLLECTION", withCallback(loadEntityCollectionSaga)),
    // takeEvery("LOAD_ENTITY_COLLECTION_PAGE", withCallback(loadEntityCollectionPageSaga)), @todo: brauchts die?
    // takeEvery("CREATE_ENTITY", withCallback(createEntitySaga)),
    // takeEvery("UPDATE_ENTITY", withCallback(updateEntitySaga)),
    // takeEvery("DELETE_ENTITY", withCallback(deleteEntitySaga)),
    // or ...
    // the next statement could be used to add takeEverys for all entityTypes,
    // but it will only be necessary as long as Action.type strings contain EntityType names
    // yield all(
    //   Object.entries(EntityType).map((e) => {
    //     return takeEvery(LOAD_PREFIX + e[1].toUpperCase(), withCallback(loadSingleEntitySaga))
    //   })
    // )
  ])
}

/*

Anlegen, ändern und löschen von Entities

Um ein Entity anzulegen (Create), zu ändern (Update) und zu löschen (Delete) (= CUD-Operationen)
werden nach dem gleichen Schema die jeweiligen Endpunkte adressiert. CUD-Sagas sind daher generalisiert
und werden über die drei Actions
* deleteModelAction
* updateModelAction
* createModelAction
angestoßen.

Sonderfälle in CUP-Operationen resultieren daraus, dass ein untergeordnetes Entity bei seinem
Hinzufügen, Ändern oder Löschen das jeweils übergeordnete Entity refreshen sollte.
Daher rufen SAGAs, die Änderungen an solchen untergeordneten Objekten durchführen, auch Refreshs
von übergeordneten Elementen hervor.
Diese Refreshs werden in der refreshConnectedEntities() EntityType-abhängig gesammelt und von den
create, update und delete-sagas aufgerufen.

Die Entities sind wie folgt miteinander hierarchisch verbunden:
Project
  -> ProjectMembership
  -> Proposal
    -> ApplicationAttachment
  -> TeamUpload
User
  -> ProjectMembership
  -> Projects (my projects)
Challenge
  -> Proposal
  -> ChallengeConcretization
  -> AttachmentDefinition
Process
FeedbackInvitation
  -> Discussion
    -> FeedbackPost
SupportRequest




Sonderfälle sind dabei folgende:


in der deleteFeedbackPostContentSaga wird nicht (!) der FeedbackPost gelöscht, sondern sein Content,
um die Diskussionskette nicht zu unterbrechen. Dafür wird ein gesonderter Endpunkt in der client.ts angesprochen:
-> switch in die client.deleteEntity eingebaut für Sonderfälle beim Löschen

create von fund löst nach erfolgreicher kreation die clearFundListAction aus (warum?)
memberapplication: createApplicationSaga triggert reload von user und myProjects
-> createApplicationSaga triggert ihrerseits createModelAction

create von newIdea (Sonderfunktion um neues Projekt zu initiieren) injected den user, reloaded user und resetted die marketresults
create von project injected den current process, lädt den user und myprojects nach
-> teilweise bereits ausgelagert in die aufrufende Page

Sonderfall beachten: Anlegen neuer Projekte ProjectActionTypes.CreateNewProject -> eine Art "Vor-Anlegen, bevor der User sich registriert hat"



Laden von Entities

Entities werden in 3 Formen geladen:
* Laden eines Einzel-Entities mit detailResult === true: loadModelAction -> generalSaga.loadSingleEntitySaga -> client.loadSingleEntity
* Laden einer Collection von Entities (mit detailResult überwiegend false):
  * Laden der ersten Seite: loadCollectionAction -> generalSaga.loadEntityCollectionSaga -> client.loadEntityCollection
  * Laden von Folgeseiten: loadCollectionPageAction -> generalSaga.loadEntityCollectionPageSaga -> client.get

"Vorgeschaltete" Sagas könnten vorbereitende Aktivitäten durchführen und mit spezifischen Actions getriggert werden,
um dann ihrerseits die hier aufgeführten load-Actions zu triggern.


sonderfälle fürs LADEN von Daten:

loadDiscussionCollectionSaga fragt:
    // @todo: how to inject the co-loaded initialPosts as separate IFeedbackPosts into the state??
    // yield put(loadCollectionSuccessAction(EntityType.FeedbackPost, discussions.map(disc => disc.initialPost as IFeedbackPost) as IHydraCollection<IFeedbackPost>))


loadCurrentUserAction

loadMarketProjectsAction

loadMyProjectBySlugOrIdAction -> Umbauen zu LoadModelAction!
loadMyProjectsAction

Statistics: alles umgestellt:
loadFundStatisticsAction
loadPublicStatisticsAction
setPublicStatisticsAction
loadProjectStatisticsAction
loadUserStatisticsAction

loadBackendCommitAction
loadSysinfoAction

loadCurrentProcessAction

-> wenn sich rausstellt, dass eine dieser Actions einen String nutzt, der ähnlich/gleich den Standardmustern sind
(LOAD_ENTITYTYPE), dann muss dieser eindeutiger gefasst werden (um den Sonderfall klarzumachen)


*/

/**
 * Triggers the loading of a single Entity, given by its ID or slug
 *
 * @param action
 * @returns
 */
function* loadSingleEntitySaga(action: ILoadSingleEntityByAction): Generator<any, INumericIdentifierModel, any> {
  const currentScopeType = (action.entityType + LOADING).toUpperCase()
  try {
    // signal to the requestreducer: the currentScopeType-request is running
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }

    let item: INumericIdentifierModel = null
    let slugOrId: string = null

    // determine id or slug
    if (hasProp(action.criteria, "id")) {
      slugOrId = action.criteria.id
    } else if (hasProp(action.criteria, SLUG_OR_ID)) {
      slugOrId = action.criteria[SLUG_OR_ID]
    }

    if (slugOrId) {
      item = yield call(apiClient.loadSingleEntity, action.entityType, slugOrId)
    } else {
      throw new Error("Unknown criteria when loading " + action.entityType)
    }

    // checking, if the fetched entity has the needed role that was requested by the dispatched action
    // if the needed role is not returned from the API there is probably an error:
    // check if the calling page/component is only accessible by users that are able to have the needed role!
    if (action.neededUsedRole && item.usedRoles && !item.usedRoles.includes(action.neededUsedRole)) {
      throw new Error(`failure.loadedWithWrongPrivilege.${action.neededUsedRole}`)
    }

    // signal to trigger scopedObjectReducer to include new entity to the store
    yield put(loadModelSuccessAction(action.entityType, item))

    // signal to the requestreducer: the currentScopeType-request was successfull
    yield put(usecaseRequestSuccessAction(currentScopeType, item))
    // if this request has a specific use case that triggers it: signalize that this use case got results
    if (action.usecaseKey) {
      yield put(usecaseRequestSuccessAction(action.usecaseKey, item))
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, item))
    }

    return item
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("loadSingleEntitySaga", errorMessage, action, err)

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    return null
  }
}

/**
 * saga to load a collection of entities
 */
export function* loadEntityCollectionSaga(action: ILoadCollectionByAction): Generator<any, IHydraCollection<INumericIdentifierModel>, any> {
  const currentScopeType = (action.entityType + COLLECTION_LOADING).toUpperCase()
  try {
    // signal to the requestreducer: the currentScopeType-request is running
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newFilteredCollectionUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }

    // initialize entities as empty list, as it may be that initializeFilterCriteria decides that API call is not necessary
    let entities: IHydraCollection<INumericIdentifierModel> = yield call(apiClient.loadEntityCollection, action.entityType, action.criteria, action.parentIri)

    // if the action is to LoadAllPagesAtOnce, load all pages
    // otherwise just work with the first-page-results
    if (action.loadAll) {
      let tmpEntities = entities
      while (tmpEntities["hydra:view"]?.["hydra:next"]) {
        // fetch the next page, coming with the previews api-call-results
        tmpEntities = yield call(apiClient.get, tmpEntities["hydra:view"]["hydra:next"])
        // merge old entity-array with new array, based on the last fetch-result
        entities = {
          ...tmpEntities,
          ["hydra:member"]: [...tmpEntities["hydra:member"], ...entities["hydra:member"]]
        }
      }
    }

    // Checking, if all fetched entities have the needed role that was requested by the dispatched action.
    // If the needed role is not returned from the API there is probably an error:
    // check if the calling page/component is only accessible by users that are able to have the needed role!
    //
    // It is not valid to check just the first entity of the collection for special case:
    // A communitymanager with special privileges on program A visits a page where all programs are shown, including A.
    // The coder made the mistake to request the program entities with a higher privilege that the page needs, but the
    // communitymanager matches this privilege for program A, and A is the first result in the collection.
    // So the check for the first result would be successful, but not for the other entities.
    if (action.neededUsedRole) {
      const entityWithWrongUsedRole = entities["hydra:member"].find((entity) => entity.usedRoles && !entity.usedRoles.includes(action.neededUsedRole))
      if (entityWithWrongUsedRole) {
        throw new Error(`failure.loadedWithWrongPrivilege.${action.neededUsedRole}`)
      }
    }

    // signal to trigger scopedObjectReducer to include new entities to the store
    yield put(loadCollectionSuccessAction(action.entityType, entities))

    // signal to the requestreducer: the currentScopeType-request was successfull
    yield put(usecaseRequestSuccessAction(currentScopeType, entities))
    // if this request has a specific use case that triggers it: signalize that this use case got results
    if (action.usecaseKey) {
      yield put(usecaseRequestSuccessAction(action.usecaseKey, entities))
      // signal a INewUsecaseRequestAction
      yield put(newFilteredCollectionUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, entities))
    }

    return entities
  } catch (err: any) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("loadEntityCollectionSaga", errorMessage, action, err)

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newFilteredCollectionUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    return null
  }
}

/**
 * Saga to load a single page of a collection of entities.
 * Page-URL must be passed by the ILoadCollectionPageAction and will mostly be taken from IHydraCollection["hydra:view"]["hydra:next"]
 * after the load of a first page of a collection.
 *
 * ACHTUNG: spezialfall: hier wird direkt apiClient.get aufgerufen, weil die URL des Page-Aufrufes in der ILoadCollectionPageAction
 * mit übergeben wird: diese wird aus nextPage übernommen, nach dem ersten Page-Aufruf
 *
 * @todo ablauf in der SAGA prüfen, Schritt-für-Schritt-Dokumentation ergänzen
 *
 */
export function* loadEntityCollectionPageSaga(action: ILoadCollectionPageAction): Generator<any, IHydraCollection<INumericIdentifierModel>, any> {
  const currentScopeType = (action.entityType + COLLECTION_LOADING).toUpperCase()

  try {
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newFilteredCollectionUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }

    const entities: IHydraCollection<INumericIdentifierModel> = yield call(apiClient.get, action.url)

    yield put(loadCollectionSuccessAction(action.entityType, entities))
    yield put(usecaseRequestSuccessAction(currentScopeType, entities))
    if (action.usecaseKey) {
      yield put(usecaseRequestSuccessAction(action.usecaseKey, entities, true))
      // signal a INewUsecaseRequestAction
      yield put(newFilteredCollectionUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, entities, true))
    }

    return entities
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("loadEntityCollectionPageSaga", errorMessage, action, err)

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newFilteredCollectionUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    return null
  }
}

/**
 * General saga to create an entity
 *
 * should be triggered by createModelAction
 */
export function* createEntitySaga(action: IModelCreateOperationInvokingAction<INumericIdentifierModel>): Generator<any, INumericIdentifierModel, any> {
  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}

  // wo jetzt currentScopeType steht, stand zuvor (beispielhaft) ScopeTypes.ProjectOperation
  // lohnt es, den scopetype noch genauer zu spezifizieren, also statt "_operation" hier "_create" zu benutzen?
  // Achtung. Sonderfall siehe: ProjectActionTypes.CreateNewProject -> zu berücksichtigen? wie behandeln?
  const currentScopeType = (action.entityType + '_operation').toUpperCase()

  try {
    // signal to the requestreducer: the currentScopeType-request is running
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }

    // transform entity to its DTO representation, if applicable
    const entityDTO: INumericIdentifierModel = transformEntityToWriteDTO(action.entityType, action.model)

    // send request to the backend api, harvest a general INumericIdentifierModel - may be casted by the specific reducer
    let entity: INumericIdentifierModel = yield call(apiClient.createEntity, action.entityType, entityDTO, action.parentIri)

    // special cases on creating an entity
    switch (action.entityType) {
      case EntityType.Discussion:
        // inject the user: on creating a Discussion in case of anonymous feedback allowed, the current user is
        // put as createdBy to recognize his Discussions within the current session to feed it back to him
        // this will only be saved locally and be rolled back when the use case is reloaded and the item replaced
        if (!(entity as IDiscussion).createdBy) {
          (entity as IDiscussion).createdBy = yield select(selectCurrentUser)
        }
        break
    }

    // signal to the use-case triggering application component: the creation was successfull
    // @todo: muss hier action.usecaseKey angehängt werden? wer genau lauscht auf die hier getriggerten actions? Zu welchem Zweck? Was ist mit Sonderfällen wie ProjectActionTypes.CreateNewProject?
    yield put(createModelSuccessAction(action.entityType, entity /* , action.usecaseKey */))

    // call function trigger refresh actions for connected entities
    entity = yield call(refreshConnectedEntities, action.entityType, entity)
    // refresh UserObjectRoles in case a user has created an entity and got a new role on it by the backend
    yield call(refreshCurrentUsersUORs, action.entityType)

    // signal to the requestreducer: the currentScopeType-request was successfull
    yield put(usecaseRequestSuccessAction(currentScopeType, entity))
    if (action.usecaseKey) {
      // signal to the use case: operation successfull
      yield put(usecaseRequestSuccessAction(action.usecaseKey, entity))
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, entity))
    }

    if (onSuccess) {
      yield call(onSuccess, entity)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return entity
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("createEntitySaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}


/**
 * General saga to update an existing entity.
 *
 * Should be triggered by UpdateModelAction.
 *
 * @todo ablauf in der SAGA prüfen, Schritt-für-Schritt-Dokumentation ergänzen
 * @todo Sonderfälle diskutieren: updateApplicationAttachmentSaga reloaded z.b. das Projekt, nachdem das ApplicationAttachment geupdated wurde, um konsistente Daten zu haben
 * @todo known issue: IProjectMembership ist ein IModel, dennoch wird hier überall mit INUmericIdentifier gearbeitet!!
 *
 * @param action
 * @returns
 */
export function* updateEntitySaga(action: IModelUpdateOperationInvokingAction<INumericIdentifierModel>): Generator<any, INumericIdentifierModel, any> {
  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}
  const currentScopeType = (action.entityType + '_operation').toUpperCase()

  try {
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }

    // transform entity to its DTO representation, if applicable
    const entityDTO: INumericIdentifierModel = transformEntityToWriteDTO(action.entityType, action.model)

    let entity: INumericIdentifierModel = yield call(apiClient.updateEntity, entityDTO)

    yield put(updateModelSuccessAction(action.entityType, entity))

    // call function trigger refresh actions for connected entities
    entity = yield call(refreshConnectedEntities, action.entityType, entity)
    // refresh UserObjectRoles in case a user has updated an entity and slug has been updated
    yield call(refreshCurrentUsersUORs, action.entityType)

    // signal to the requestreducer: the currentScopeType-request was successfull
    yield put(usecaseRequestSuccessAction(currentScopeType, entity))
    if (action.usecaseKey) {
      yield put(usecaseRequestSuccessAction(action.usecaseKey, entity))
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, entity))
    }

    if (onSuccess) {
      yield call(onSuccess, entity)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return entity
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("updateEntitySaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

/**
 * General saga to delete an entity.
 *
 * Should be triggered by DeleteModelAction.
 *
 * @param action
 * @returns true, if the deletion was successful, otherwise: false
 */
export function* deleteEntitySaga(action: IModelDeleteOperationInvokingAction<INumericIdentifierModel>): Generator<any, boolean, any> {
  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}
  const currentScopeType = (action.entityType + '_operation').toUpperCase()

  try {
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }

    // send request to the API
    yield call(apiClient.deleteEntity, action.model)
    yield put(deleteModelSuccessAction(action.entityType, action.model))

    // call function trigger refresh actions for connected entities
    yield call(refreshConnectedEntities, action.entityType, action.model)
    // refresh UserObjectRoles in case a user has deleted an entity and lost role
    yield call(refreshCurrentUsersUORs, action.entityType)

    // signal to the requestreducer: the currentScopeType-request was successfull
    yield put(usecaseRequestSuccessAction(currentScopeType, action.model))
    if (action.usecaseKey) {
      yield put(usecaseRequestSuccessAction(action.usecaseKey, action.model))
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, action.model))
    }

    if (onSuccess) {
      yield call(onSuccess)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return true
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("deleteEntitySaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return false
  }
}


/**
 * Generic Saga to upload a file. Uses endpoints defined in src/api/client.ts
 */
function* uploadFileSaga(action: IUploadApiRequestInvokingAction<INumericIdentifierModel>): Generator<any, INumericIdentifierModel, any> {
  const { onSuccess, setErrors, setSubmitting } = action.callbackActions || {}

  const currentScopeType = (action.entityType + '_operation').toUpperCase()
  try {
    // signal: request is running (old scopedRequest stuff)
    yield put(usecaseRequestRunningAction(currentScopeType))
    // if this request has a specific use case that triggers it: signalize that this use case is starting
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey))
    }
    // call the api, get updated entity
    const entity: INumericIdentifierModel = yield call(apiClient.uploadFile, action.uploadType, action.model, action.file)

    // deliver update-signal to the "old" redux system to put the received data to state.data
    yield put(updateModelSuccessAction(action.entityType, entity))

    yield call(refreshConnectedEntities, action.entityType, action.model)
    // signal to the requestreducer: the currentScopeType-request was successfull
    yield put(usecaseRequestSuccessAction(currentScopeType, action.model))
    if (action.usecaseKey) {
      yield put(usecaseRequestSuccessAction(action.usecaseKey, action.model))
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestSuccessAction(action.entityType, action.usecaseKey, entity))
    }

    if (onSuccess) {
      yield call(onSuccess, entity)
    }
    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return entity
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("uploadFileSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(usecaseRequestRunningAction(currentScopeType, errorMessage))
    // if this request has a specific use case that triggers it: signalize that this use case has an error
    if (action.usecaseKey) {
      // signal a INewUsecaseRequestAction
      yield put(newSingleEntityUsecaseRequestRunningAction(action.entityType, action.usecaseKey, errorMessage))
    }

    // setSubmitting(false) is called at the very end of the method
    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}

/**
 * function to refresh connected entities for every entitytype
 *
 * @param entityType the entitytype that should be processed
 * @param entity the entity to be used
 * @returns a (possibly) changed entity
 */
function* refreshConnectedEntities(entityType: EntityType, entity: INumericIdentifierModel): Generator<any, INumericIdentifierModel, any> {

  // @todo wie kann in JEDEM Fall ein Handling für delete aussehen? kann da auch nach dem Lösch-Akt auf action.model zurückgegriffen werden? Ist das konsistent?
  switch (entityType) {
    case EntityType.Category:
      // @todo: delete all projects in the state an project-related states when a category is deleted to force project refresh for the PM after deleting a ategory
      break
    case EntityType.ProposalAttachment:
      // reload the project to have a consistent model in the store, instead of integrating the
      // attachment manually
      // @todo test: ist das auch im Fall eines Delete von IProposalAttachment passend?
      // @todo bzgl delete: in deleteProposalAttachmentSaga gab es kein refresh des IProject; und der Kommentar dazu war:
      // "we cannot refresh the project here, the action.model is probably an ProposalAttachment
      // taken from a project so it contains no project or proposal reference,
      // also the DELETE returns no date -> leave the refresh to the caller"

      const proposal = iriFromIModelOrIRI((entity as IProposalAttachment).proposal)
      if (!proposal) {
        throw new Error("Function selectProposalByProposalAttachmentId is missing, @see generalSaga")
      }
      // on proposal attachment entitys, that come "huckepack" with their proposal, they do possibly
      // not have the proposal attribute
      // but it is sure, that the "huckepack" entity is in the store, so we can find it to refresh it
      // that happens on "overview" pages, that allow delete of sub-elements
      const project: IProject = yield select(selectProjectByProposalIri, proposal)
      yield putWait(loadModelAction(EntityType.Project, project.id))

      break
    case EntityType.Discussion:
      // if the discussion belongs to an feedbackinvitation, trigger its re-load for updated discussionCount
      if ((entity as IDiscussion).feedbackInvitation) {
        yield put(loadModelAction(EntityType.FeedbackInvitation, idFromIModelOrIRI((entity as IDiscussion).feedbackInvitation)))
      }
      break
    case EntityType.FeedbackInvitation:
      break
    case EntityType.FeedbackPost:
      // (re)load the correspondig discussion, to update the discussion.postCount
      // @todo is there a better way than (re-)loading?
      yield put(loadModelAction(EntityType.Discussion, idFromIModelOrIRI((entity as IFeedbackPost).discussion)))
      break
    case EntityType.Challenge:
      break
    case EntityType.Proposal:
      // reload the project to have a consistent model in the store, instead of integrating the
      // application manually
      // hint: if the proposal was directly accessed through the proposal endpoint by a processmanager, the proposal has the project property,
      // otherwise the project proerty is not available, therefor it is needed to select the project by the proposal
      // in the user area, the project is there, because the the user can only access their proposal through the loaded project
      let projectByProposal = (entity as IProposal).project
      if (!projectByProposal) {
        projectByProposal = yield select(selectProjectByProposalIri, iriFromIModelOrIRI(entity as IProposal))
      }
      yield putWait(loadModelAction(EntityType.Project, idFromIModelOrIRI(projectByProposal)))
      break
    case EntityType.AttachmentDefinition:
      let attachmentDefChallenge = (entity as IAttachmentDefinition).challenge
      // on attachment definition entitys, that come "huckepack" with their challenge, they do not have the challenge attribute
      // but it is sure, that the "huckepack" entity is in the store, so we can find it to refresh it
      // that happens on "overview" pages, that allow delete of sub-elements
      if (!attachmentDefChallenge) {
        attachmentDefChallenge = yield select(selectChallengeByAttachmentDefinitionId, entity.id)
      }
      yield putWait(loadModelAction(EntityType.Challenge, idFromIModelOrIRI(attachmentDefChallenge)))
      break
    case EntityType.ChallengeConcretization:
      // reload the challenge to have a consistent model in the store
      let concretizationChallenge = (entity as IChallengeConcretization).challenge
      // on concretization entitys, that come "huckepack" with their challenge, they do not have the challenge attribute
      // but it is sure, that the "huckepack" entity is in the store, so we can find it to refresh it
      // that happens on "overview" pages, that allow delete of sub-elements
      if (!concretizationChallenge) {
        concretizationChallenge = yield select(selectChallengeByConcretizationId, entity.id)
      }
      yield putWait(loadModelAction(EntityType.Challenge, idFromIModelOrIRI(concretizationChallenge)))
      break
    case EntityType.Program:
      break
    case EntityType.Project:
      break
    // @todo multi refactor for UserObjectRole as soon as we know how to delete or update an UserObjectRole
    case EntityType.ProjectFollowership:
    case EntityType.ProjectMembership:
      const projectUserRelation = entity as AbstractProjectUserRelation

      // NOTE Code has been integrated from different handlers of EntityType.ProjectMembership.
      // See below for old code.


      // we need to load currentUser (and special project lists), if projectUserRelation is currentUser's relation
      // since the condition is rather complex (and entityType specific), we build it slowly
      let loadCurrentUser = false
      // projectMembership and projectFollowership has no user property, if they're delivered as part of an (current)User entity
      if (!projectUserRelation.user) {
        loadCurrentUser = true
      } else {
        // NOTE this branch will probably never evaluate to true, b/c we look for exactly those cases
        // where projectMembership/projectFollowership was part of currentUser (and hence has been delivered in its entity)
        if (typeof projectUserRelation.user === "string") {
          // if it's a string we must determine if the according (entityType specific) list contains an entry with the same @id
          let list: AbstractProjectUserRelation[] = []
          switch (entityType) {
            case EntityType.ProjectFollowership:
              list = yield select(selectMyFollowerships)
              break
            case EntityType.ProjectMembership:
              list = yield select(selectMyMemberships)
              break
          }
          loadCurrentUser = list?.some((m: IModel) => m["@id"] === projectUserRelation["@id"])
        } else {
          // otherwise just check user id
          const currentUserId: number = yield select(selectCurrentUserId)
          loadCurrentUser = currentUserId === idFromIModelOrIRI(projectUserRelation.user)
        }
      }

      // @todo multi: am User-Objekt hängen keine Membership-Verbindungen mehr, dafür müssen wohl die UserObjectRoles geupdated werden
      // Achtung: müßten nicht auch die Projekt-Memberships geupdated werden, die vom Projects-Endpunkt abgerufen werden?
      if (loadCurrentUser) {
        // refresh currentUser to implicitely refresh all connected project lists (wait for the result!)
        yield putWait(loadCurrentUserAction())
        // refresh special lists if adequate
        switch (entityType) {
          case EntityType.ProjectFollowership:
            break
          case EntityType.ProjectMembership:
            // yield put(newLoadCollectionAction(EntityType.Project, null, ScopeTypes.MyProjects))
            break
        }
      }
      // do we need to load the corresponding project?
      // just load the project, anyhow
      yield put(loadModelAction(EntityType.Project, (projectUserRelation.project as IProject).id))
      break
    case EntityType.SupportRequest:
      break
    case EntityType.TeamUpload:
      // im original: kein putwait!
      // refresh the project
      if ((entity as ITeamUpload).project) {
        yield put(loadModelAction(EntityType.Project, idFromIModelOrIRI((entity as ITeamUpload).project)))
      }
      break
    case EntityType.User:
      break
  }

  return entity
}


/**
 * function to refresh the current users object roles after creating an entity
 * which co-created a role for that user or after deleting an entity, which co-deleted the role or
 * after updating an entity, where e.g. the slug has been updated
 *
 * @param entityType the entitytype that has been created
 */
function* refreshCurrentUsersUORs(entityType: EntityType): Generator<any, any, any> {
  const currentUser: IUser = yield select(selectCurrentUser)

  if (isEntityTypeUserObjectRole(entityType)) {
    yield putWait(newLoadCollectionAction(
      EntityType.UserObjectRole,
      null,
      usecaseKeyForLoadCollection(null, null, currentUser["@id"]),
      currentUser["@id"],
      true /* loadAll */
    ))
  }
}

/**
 * generic saga to load statistics of type IStatistics
 *
 * @todo multi probably split into 2 sagas, global and single -> https://futureprojects.atlassian.net/browse/FCP-1703
 */
function* loadStatisticsSaga(action: IStatisticsApiRequestInvokingAction): Generator<any, IStatistics, any> {
  try {
    yield put(statisticsRequestRunningAction(action.usecaseKey))
    const statistics: IStatistics = yield call(apiClient.loadStatistics, action.usecaseKey, action.id)

    // signal to the requestreducer: the current type-request was successfull
    yield put(statisticsRequestSuccessAction(action.usecaseKey, statistics))

    return statistics
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR

    showErrorsInTestEnvironment("loadStatisticsSaga", errorMessage, action, err)

    // if an error occurred: signalize that the current type-request has failed with the error message
    yield put(statisticsRequestRunningAction(action.usecaseKey, errorMessage))

    return null
  }
}