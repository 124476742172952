import classNames from "classnames"
import withTranslation from 'next-translate/withTranslation'
import { ReactNode } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { InputType } from "reactstrap/types/lib/Input"

import FAQPopup from "@components/common/faq/FAQPopup"
import TranslatedHtml from "@components/common/TranslatedHtml"

import FormikElement, { IBaseFormikProps } from "./FormikElement"


interface IProps extends IBaseFormikProps {
  children: ReactNode
  className: string
  /**
   * set true, if the visible character-count should count line breaks:
   * if line breaks are allowed, they need to be counted to avoid overrun the character limit.
   * If they are filtered out before sending the content, they should not be counted.
   * */
  countLineBreaks?: boolean
  i18n: any
  name: string
  noGroup?: boolean
  placeholder?: string
  type?: InputType
  value?: any
  onFocus?: () => void
  disabled?: boolean
  maxLength?: number
  /** minimum length of the content to be shown to the user */
  minLength?: number
}

/**
 * this component provides a Formik-based Input-Form-Element
 */
class FormikInput extends FormikElement<IProps> {

  calculateTextLength = (text: string): number => {
    return text
      ? this.props.countLineBreaks
        ? text.length
        : text.replace(/(?:\r|\n)/g, '').length
      : 0
  }


  public render = (): ReactNode => {

    const lengthContainerClassname = classNames(
      "textlength",
      // text should be red for warning, if the content length exceeds the given maxLength
      { "text-danger": this.props.type === "textarea" && this.calculateTextLength(this.props.field.value as string) > this.props.maxLength }
    )

    // extract all properties not allowed / not needed for the input element so they don't get
    // passed down and appear in the HTML
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, countLineBreaks, field, form, help, i18n, id, label, meta, noGroup = false,
      placeholder, type, value, onFocus, disabled, maxLength, minLength, role, ...props } = this.props
    const labelText = this.labelText()

    const element = <>
      <div>
        {labelText.length > 0 &&
          <Label for={field.name}>{labelText}
            {this.props.faqpopup && /* set an faq-popup, if given */
              <FAQPopup {...this.props.faqpopup} />
            }
          </Label>
        }
        {this.helpElement()}
      </div>
      <Input
        {...field}
        {...props}
        id={id || field.name}
        name={field.name}
        invalid={this.hasError()}
        placeholder={placeholder ? i18n.t(placeholder) : null}
        type={type || "text"}
        value={value === null ? "" : value}
        onFocus={onFocus}
        disabled={disabled}
        role={role || "input"}
      >{children}</Input>
      {type === "textarea" && <div className={lengthContainerClassname}>
        {maxLength
          ? <TranslatedHtml content="form.input.textLengthWithMaxLength" params={{ length: this.calculateTextLength(value as string), maxLength }} />
          : <TranslatedHtml content="form.input.textLength" params={{ length: this.calculateTextLength(value as string) }} />
        }
        {minLength && (this.calculateTextLength(value as string) < minLength) && <>
          , <TranslatedHtml content="form.input.minLength" params={{ minLength }} />
        </>}
      </div>}
      {this.errorElement()}
    </>

    return noGroup ? element : <FormGroup>{element}</FormGroup>
  }
}

export default withTranslation(FormikInput, "common")
