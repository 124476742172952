import { Action } from "redux"

/*
 * This file contains all actions and needed enums and interfaces, to handle smaller states of the application:
 * - visibility and content of the the Flyout (for FAQ)
 * - settings for a user while current session
 * - ...
 */

/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */


/**
 * defines all possible Action-names regarding the Flyout
 */
export enum FlyoutActionTypes {
  ToggleFlyoutVisibility = "TOGGLE_FLYOUT_VISIBILITY",
  SetFlyoutContentURI = "SET_FLYOUT_CONTENT_URI"
}

/**
 * defines all possible Action-names regarding the Settings
 */
export enum SettingsActionTypes {
  SetDetailedComments = "SET_SETTINGS_DETAILED_COMMENTS",
  SetCustomerPreview = "SET_SETTINGS_CUSTOMER_PREVIEW"
}

/**
 * defines all possible Action-names regarding the Map
 */
export enum MapActionTypes {
  SetMapEditingState = "SET_MAP_EDITING_STATE",
}


/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

export interface IFlyoutAction extends Action {
  type: FlyoutActionTypes
}

export interface ISetFlyoutContentURIAction extends IFlyoutAction {
  contentURI: string
  type: FlyoutActionTypes.SetFlyoutContentURI
}

/**
 * Action regarding the map editing:
 * to avoid re-rendering the map the editing state is stored in the global state
 * to allow rendering the submit button but not the map
 */
export interface IMapEditingAction {
  type: MapActionTypes.SetMapEditingState
  editing: boolean
}

/** interface to define Actions for manipulating the settings */
export interface ISettingsAction extends Action {
  type: SettingsActionTypes
}

/** interface for an Action to manipulate settings.detailedComments */
export interface ISetSettingsDetailedCommentsAction extends ISettingsAction {
  type: SettingsActionTypes.SetDetailedComments
  detailedComments: boolean
}

/** interface for an Action to manipulate settings.showHiddenPreview */
export interface ISetSettingsCustomerPreviewAction extends ISettingsAction {
  type: SettingsActionTypes.SetCustomerPreview
  customerPreview: boolean
}

/**
 * summarize all possible Actions to control the ApplicationActions to one type
 */
export type ApplicationActions =
  IFlyoutAction
  | ISettingsAction
  | IMapEditingAction

/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

export const toggleFlyoutVisibilityAction = (): IFlyoutAction => ({
  type: FlyoutActionTypes.ToggleFlyoutVisibility,
})

export const setFlyoutContentURIAction = (contentURI: string): ISetFlyoutContentURIAction => ({
  contentURI,
  type: FlyoutActionTypes.SetFlyoutContentURI
})

/**
 * Used to change the state when the user edits the map.
 * Necessary to store this information in the global state to avoid unwanted re-renderings.
 *
 * @param editing true, if user is editing the map, false if editing was finished
 */
export const setMapEditingAction = (editing: boolean): IMapEditingAction => ({
  editing,
  type: MapActionTypes.SetMapEditingState
})

/**
 * helper function to create an Action to change the detailedComments in state.applicationstate.settings
 * currently (23.06.2023) unused
 */
export const setSettingsDetailedComments = (detailedComments: boolean): ISetSettingsDetailedCommentsAction => ({
  type: SettingsActionTypes.SetDetailedComments,
  detailedComments
})

/**
 * helper function to create an Action to change the customerPreview in state.applicationstate.settings
 */
export const setSettingsCustomerPreview = (customerPreview: boolean): ISetSettingsCustomerPreviewAction => ({
  type: SettingsActionTypes.SetCustomerPreview,
  customerPreview
})