import { createHash, randomBytes } from 'crypto'

import { getOIDCConfig, OIDCLocalStorageKeys } from '../config'
import { IOIDCProvider } from '../models/IOIDCProvider'

/**
 * Encodes the content of the given Buffer with base64url encoding.
 *
 * Buffer.toString("base64url") should do the job, but in our package/configuration it throws an error,
 * most possibly due to different overwrites of Buffer.prototype.toString.
 *
 * @see https://futureprojects.atlassian.net/browse/FCP-1640
 *
 * @param b
 * @returns `b`'s content, base64url encoded
 */
const encodeBase64Url = (b: Buffer): string =>
  b.toString('base64')
    .replaceAll(/\+/g, '-')
    .replaceAll(/\//g, '_')
    .replaceAll(/=+$/g, '')

/**
 * Generates a random string of given length, encoded with base64url.
 *
 * @param length The desired length
 * @returns The random string
 */
const generateRandomString = (length: number): string => {
  // return randomBytes(length).toString("base64url")
  return encodeBase64Url(randomBytes(length))
    // since base64url encoding lengthens the string, we must cut it to the required length
    .slice(0, length)
}

/**
 * Generates a PKCE code challenge for a given code verifier.
 *
 * NOTE maybe this should be async, if createHash is not fast enough?
 * @todo oauth: keep an eye on this issue.
 *
 * @param codeVerifier The PKCE code verifier
 * @returns The challenge
 */
const generateCodeChallenge = (codeVerifier: string): string => {
  // const hash = createHash("sha256").update(codeVerifier).digest("base64url")
  return encodeBase64Url(createHash("sha256").update(codeVerifier).digest())

  // See NOTE above: if we need asynch codeChallenge generation, we could use this code:
  // first draft from chatGPT, using asynch funtions
  // const encoder = new TextEncoder()
  // const data = encoder.encode(codeVerifier)
  // const digest = await crypto.subtle.digest('SHA-256', data)
  // return encodeBase64Url(btoa(String.fromCharCode(...new Uint8Array(digest))))
}

/**
 * A string (or character) used to separate the OIDC provider name from the state string in the `state` URL parameter.
 */
export const AUTH_STATE_SEPARATOR = ":"

/**
 * Redirects the user to the given OIDC provider after creating the auth url for this provider.
 *
 * @param provider The OIDC provider to create a route for
 */
export const redirectToAuthProvider = (provider: IOIDCProvider): void => {
  const authState = generateRandomString(16) // we could use uuidv4()
  const codeVerifier = generateRandomString(128) // max 128 chars, @see https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
  const codeChallenge = generateCodeChallenge(codeVerifier)
  const nonce = generateRandomString(32)

  // @todo oauth check if we're using localStorage right (keep in mind that we allow multiple user sessions in multiple browser tabs)
  // clear storage before writing new values - it seems as if old values somehow remain in a cache?
  clearOIDCItemsFromStorage()
  getOIDCConfig().storageAPI.setStorageItem(OIDCLocalStorageKeys.AuthState, authState)
  getOIDCConfig().storageAPI.setStorageItem(OIDCLocalStorageKeys.CodeVerifier, codeVerifier)
  getOIDCConfig().storageAPI.setStorageItem(OIDCLocalStorageKeys.Nonce, nonce)

  // @todo oauth refactor the param structure to an (exported) type.
  const data = new URLSearchParams()
  data.append("response_type", "code")
  data.append("client_id", provider.clientId)
  data.append("redirect_uri", getOIDCConfig().redirectURI)
  data.append("scope", provider.scope)
  data.append("state", provider.shortName + AUTH_STATE_SEPARATOR + authState)
  data.append("nonce", nonce)
  data.append("code_challenge", codeChallenge)
  data.append("code_challenge_method", "S256")

  const authUrl = provider.authorizeUrl + "?" + data.toString()

  getOIDCConfig().loggerAPI.debug("redirectToAuthProvider, authUrl", authUrl)

  getOIDCConfig().routeTo(authUrl)
}

/**
 * Clear everything that has been in written in the store by us.
 */
export const clearOIDCItemsFromStorage = (): void => {
  getOIDCConfig().storageAPI.removeStorageItem(OIDCLocalStorageKeys.AuthState)
  getOIDCConfig().storageAPI.removeStorageItem(OIDCLocalStorageKeys.CodeVerifier)
  getOIDCConfig().storageAPI.removeStorageItem(OIDCLocalStorageKeys.Nonce)
}