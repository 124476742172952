import { Action } from "redux"

import { IFeedbackInvitation } from "@api/schema"
import { IFormikActions } from "@redux/helper/actions"


/* ************************************************************************** */
/* Enum(erations) to standardize the usage of frequently used constants       */
/* ************************************************************************** */

/**
 * this enum defines the possible types of actions
 */
export enum FeedbackActionTypes {
  ActivateFeedbackInvitation = "ACTIVATE_FEEDBACKINVITATION",
}

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

/**
 * This interface defines a concrete Action for activating an FeedbackInvitation
 */
export interface IActivateFeedbackInvitationAction extends Action {
  feedbackInvitation: IFeedbackInvitation
  type: FeedbackActionTypes.ActivateFeedbackInvitation
  actions: IFormikActions
}

export type FeedbackActions = IActivateFeedbackInvitationAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */

/**
 * This function helps to activate an IActivateFeedbackInvitationAction
 *
 * @param fbi FeedbackInvitation to be activated
 * @returns An activateFeedbackInvitationAction
 */
export const activateFeedbackInvitationAction = (fbi: IFeedbackInvitation, actions: IFormikActions): IActivateFeedbackInvitationAction => ({
  feedbackInvitation: fbi,
  type: FeedbackActionTypes.ActivateFeedbackInvitation,
  actions
})
