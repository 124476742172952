
/**
 * Data structure of an OIDC provider.
 * NOTE structure and content are specified in the host application backend.
 */
export interface IOIDCProvider {
  /**
   * URL of the OIDC provider from where the host application is requesting an authCode (aka auth request).
   */
  authorizeUrl: string
  /**
   * Client ID of the host application. Must be known to the OIDC provider.
   */
  clientId: string
  /**
   * Name of the provider to be displayed to the user
   */
  displayName: string
  /**
   * List of OAuth scopes for the auth request (e.g. profile, email, openid).
   * Using "openid" will ensure that an OIDC token is returned from `tokenURL`.
   */
  scope: string
  /**
   * Short name of the OIDC provider, used for cross references and indexing.
   */
  shortName: string
  /**
   * URL to fetch the token(s) from the OIDC provider, using the authCode requested earlier.
   */
  tokenUrl: string
  /**
   * Flag whether users must accept this platform's terms of use before registering.
   */
  termAcceptanceRequired: boolean
  /**
   * URL to fetch the userinfo JSON using an AccessToken that has been provided
   * to the client via a request to tokenUrl (aka token exchange).
   */
  userInfoUrl: string
}

/** Selects the provider from the OIDCProvider list, that has the given shortName. */
export const retrieveProvider = (providers: IOIDCProvider[], shortName: string): IOIDCProvider =>
  providers.find(f => f.shortName === shortName)
