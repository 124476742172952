import { IProject } from "@api/schema"
import { AppState } from "@redux/reducer"

import { OnboardingType } from "./definitions"

export const selectOnboardingTypeNewIdea = (s: AppState): IProject =>
  s.onboardingData.newIdea

export const selectOnboardingTypeNewProjectFromIdea = (s: AppState): IProject =>
  s.onboardingData.newProjectFromIdea

export const selectOnboardingTypeNewProjectMemberApplication = (s: AppState): IProject =>
  s.onboardingData.newProjectMemberApplication

export const selectOnboardingTypeNewProjectFollowership = (s: AppState): IProject =>
  s.onboardingData.newProjectFollowership

export const selectOnboardingType = (state: AppState): OnboardingType => {
  // NOTE we're silently ignoring the case where more than 1 onboarding data is present

  if (state.onboardingData.newIdea) {
    return OnboardingType.NewIdea
  }
  if (state.onboardingData.newProjectFromIdea) {
    return OnboardingType.NewProjectFromIdea
  }
  if (state.onboardingData.newProjectMemberApplication) {
    return OnboardingType.NewProjectMemberApplication
  }
  if (state.onboardingData.newProjectFollowership) {
    return OnboardingType.NewProjectFollowership
  }

  return OnboardingType.None
}

/**
 * Test if there exists an onboarding data element of another type and returns the type,
 * otherwise null.
 *
 * @param state The AppState
 * @param excludedOnboardingType The OnboardingType that should be excluded
 * @returns the type of the other onboarding data element in the global state.onboardingData; otherwise null
 */
export const selectOtherExistingOnboardingType = (state: AppState, excludedOnboardingType: OnboardingType): OnboardingType => {
  const type = selectOnboardingType(state)
  if ([OnboardingType.None, excludedOnboardingType].includes(type)) {
    return null
  } else {
    return type
  }
}