import ActiveLink from "@components/common/ActiveLink"
import Icon, { IconName } from "@components/common/Icon"
import { useTooltip } from "@services/tooltipHook"

interface IProps {
  href: string
  icon: IconName
  /**
   * A (translated) text ready for display.
   */
  title: string
}

/**
 * Provides an icon for navigation as ActiveLink with a tooltip.
 * Note that `title` is a string that is already translated, and not an i18n key.
 */
const NavIcon: React.FC<IProps> = ({ href, icon, title }: IProps) => {

  const { tooltipTargetRef, ToolTip } = useTooltip(title)

  return <>
    <ActiveLink
      href={href}
      title={title}
      className="actionicon"
    >
      <span ref={tooltipTargetRef}>
        <Icon name={icon} size={24} />
      </span>
    </ActiveLink>
    {ToolTip}
  </>
}

export default NavIcon
