import { UserRole } from "@api/schema"

/**
 * defines all possible Action-names regarding the authentification
 */
export enum AuthActionTypes {
  InitAuth = "INIT_AUTH",
  RefreshToken = "REFRESH_TOKEN",
  SetJwt = "SET_JWT",
  UserIsIdle = "USER_IS_IDLE",
}

export interface IJwtState {
  /** encoded token, as coming from the backend */
  jwt: string
  jwtExpiresAt: number
  refreshToken: string
  refreshTokenExpiresAt: number
  roles: UserRole[]
  userId: number
  username: string
}

export interface IAuthState extends IJwtState {
  initialized: boolean
}