import { Action } from "redux"

import { ISysinfo } from "@api/schema"
import { PlatformActionTypes } from "@basics/platform"


/*
* Actions especially for general purposes for the application itself.
*/

/* ************************************************************************** */
/* Interfaces that define Actions                                             */
/* ************************************************************************** */

interface IPlatformAction extends Action {
  type: PlatformActionTypes
}

export interface ILoadBackendCommitAction extends IPlatformAction {
  type: PlatformActionTypes.LoadBackendCommit
}

export interface ISetBackendCommitAction extends IPlatformAction {
  hash: string
  type: PlatformActionTypes.SetBackendCommit
}

export interface ILoadSysinfoAction extends IPlatformAction {
  type: PlatformActionTypes.LoadSysinfo
}

export interface ISetSysinfoAction extends IPlatformAction {
  sysinfo: ISysinfo
  type: PlatformActionTypes.SetSysinfo
}

export interface ISetAuthTTLAction extends IPlatformAction {
  ttl: number
  type: PlatformActionTypes.SetAuthTTL
}

/**
 * Interface to combine all Actions
 */
export type PlatformAction =
  | ILoadBackendCommitAction
  | ISetBackendCommitAction
  | ILoadSysinfoAction
  | ISetSysinfoAction
  | ISetAuthTTLAction


/* ************************************************************************** */
/* Helper-Functions to create Actions in a more easy way                      */
/* ************************************************************************** */
/**
 * Action to load the current backend commit
 */
export const loadBackendCommitAction = (): ILoadBackendCommitAction => ({
  type: PlatformActionTypes.LoadBackendCommit
})

/**
 * Action to store the loaded backend commit
 */
export const setBackendCommitAction = (hash: string): ISetBackendCommitAction => ({
  hash,
  type: PlatformActionTypes.SetBackendCommit
})


/**
 * Action to load system information about the platform
 */
export const loadSysinfoAction = (): ILoadSysinfoAction => ({
  type: PlatformActionTypes.LoadSysinfo
})

/**
 * Action to store the loaded system information
 */
export const setSysinfoAction = (sysinfo: ISysinfo): ISetSysinfoAction => ({
  sysinfo,
  type: PlatformActionTypes.SetSysinfo
})


/**
 * Action to store the remaining time the current authentification of the user has (TTL = time to live)
 */
export const setAuthTTLAction = (ttl: number): ISetAuthTTLAction => ({
  ttl,
  type: PlatformActionTypes.SetAuthTTL
})