import SVG from "react-inlinesvg"

import { useTooltip } from "@services/tooltipHook"

/**
 * pre-defined icons that are known to the platform
 * stored in the given path (see: below)
 *
 * ordered alphabetically as if expecting a .svg as extension (for test)
 */
export const icons = [
  // customer-icons: custom-specific icons, for example:
  // "customer-icon-guide",
  // "customer-icon-info",

  // default platform-icons
  "alert",
  "arrow-left",
  "arrow-right",
  "arrow-upward",
  "calendar",
  "cancel",
  "caret",
  "challenge",
  "check-in-circle",
  "check-simple",
  "check",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "circle",
  "clock",
  "comment-add",
  "comment-ask",
  "comment-critizise",
  "comment-hint",
  "comment-support",
  "comment-troll",
  "comment",
  "compasses",
  "document",
  "download-cloud",
  "download",
  "duplicate",
  "euro",
  "facebook",
  "filter",
  "flag",
  "flash-light",
  "fullscreen",
  "gift",
  "goal",
  "grid",
  "hand",
  "info-bubble",
  "instagram",
  "light-bulb",
  "lock-closed",
  "lock-open",
  "mail-group",
  "mail",
  // map icons
  "map-line",
  "map-marker-ball",
  "map-marker-basketball",
  "map-marker-bbq",
  "map-marker-bench",
  "map-marker-fire",
  "map-marker-hardwood-tree",
  "map-marker-sandbox",
  "map-marker-soccer-field",
  "map-marker-softwood-tree",
  "map-marker-spraybottle",
  "map-marker-sw",
  "map-marker-swing",
  "map-marker-tabletennis",
  "map-marker-toilet",
  "map-marker-trashbin",
  "map-marker-water",
  "map-marker",
  "map-polygon",

  "market",
  "message",
  "minus",
  "money-bag",
  "money",
  "monitor",
  "paper-clip",
  "partner",
  "pdf-file",
  "pdf-generate",
  "pencil",
  "plus",
  "pot",
  "preferences",
  "project-implementation",
  "project",
  "question-mark-circle",
  "refresh",
  "revert",
  "save",
  "scale",
  "search",
  "share",
  "sort-ascending",
  "sort-descending",
  "sort",
  "stakeholder",
  "status-offline",
  "status-online",
  "take-idea",
  "teamuploads",
  "tenant",
  "thumbs-up",
  "to-do",
  "trash",
  "triangle",
  "upload",
  "user-add",
  "user-multiple",
  "user",
  "videoconference",
  "work-package",
  "world",
] as const

/**
 * allowed icon names
 */
export type IconName = typeof icons[number]

/**
 * function to return a valid URL to the svg with the given valid name
 */
export const iconUrl = (name: IconName): string => {
  return `/assets/icons/${name}.svg`
}

/**
 * folder of the icons
 */
export const ICON_FOLDER = './public/assets/icons/'
export const SVG_EXTENSION = '.svg'

interface IProps {
  className?: string
  name: IconName
  size: number
  tooltip?: string
  /**
   * data-testid is by default set to "icon-"name, whereas "name" is the content of the name attribute.
   * So in tests icons can be found by
   * const icon = getByTestId("icon-save")
   * when the "save"-icon is expected.
   *
   * To differ from that default, set a different data-testid!
   */
  "data-testid"?: string
}

/**
 * This component provides an Icon from a given list of possible Icons.
 */
const Icon: React.FC<IProps> = (props: IProps) => {
  const { className = "", tooltip, name, size } = props

  const { tooltipTargetRef, ToolTip } = useTooltip(tooltip)

  if (icons.includes(name))
    return <>
      <span
        ref={tooltipTargetRef}
        data-testid={props["data-testid"] || "icon-" + name}
      >
        <SVG src={iconUrl(name)} height={size} width={size} className={className} />
      </span>
      {ToolTip}
    </>
  else return <>
    <span ref={tooltipTargetRef}>[?]</span>
    {ToolTip}
  </>
}

export default Icon
