import { AppState } from "@redux/reducer"

/**
 * Returns the current visibility of the Flyout.
 * Use it by useSelector(isFlyoutVisible) to get the value.
 *
 * @param state
 * @returns boolean: Is the Flyout visible? true: yes, false: no
 */
export const isFlyoutVisible = (state: AppState): boolean => state.applicationStates.flyout.isVisible

/**
 * Returns the current value of the contentURI.
 * Use this method with useSelector(selectFlyoutContentURI) to get the value.
 *
 * @param state
 * @returns contentURI as string
 */
export const getFlyoutContentURI = (state: AppState): string => state.applicationStates.flyout.contentURI

/**
 * Function to get the map editing state
 *
 * @param state
 * @returns true, if user is currently editing the map, otherwise false
 */
export const isMapEditing = (state: AppState): boolean => state.applicationStates.mapUserEditing