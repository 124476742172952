import { platformIsInTestEnvironment } from "./platform"

/**
 * Types of unhandled unexpected errors
 */
export declare type UnhandledUnexpectedErrorType = 'server' | 'i18n' | 'fetching-url'

/**
 * Log unhandled unexpected errors, e.g. on console or via Sentry.
 *
 * @todo add Sentry handling, including a setting/environment flag
 */
export const logUnhandledUnexpectedError = (errorMsg: string, type: UnhandledUnexpectedErrorType): void => {
  if (!platformIsInTestEnvironment()) {
    // eslint-disable-next-line no-console
    console.log("Unhandled unexpected error of type '" + type + "': " + errorMsg)
  }
}

/**
 * Allowed types of PageErrors.
 * NOTE: must be numbers!
 */
export enum PageErrorCode {
  Error404 = 404,
  Error403 = 403,
}