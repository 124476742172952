
import hasProp from "lodash/has"
import { Button, Card, CardBody, CardHeader, Collapse } from 'reactstrap'

import { withDynamicNamespaces } from '@components/hoc/withDynamicNamespaces'
import { useToggleState } from '@services/hooks/useToggleState'
import { NamespaceShortcut } from '@services/i18n'

import { FAQKeys, FAQTopicType } from './FAQHelper'
import { useSetFAQKey } from './FAQHooks'
import Icon from '../Icon'

const usedNamespaces: NamespaceShortcut[] = ["faq-meta"]

interface ITopic extends IQuestions {
  topicTitle?: string
  isOpen?: boolean
}

interface IQuestions {
  faqNode: FAQTopicType
  faqKey: string | undefined
}

/**
 * It show's a topic with its title as well as its questions, which the user can collapse, so that just the topic title is visible.
 *
 */

const FAQTopicCard = ({ faqNode, topicTitle, faqKey, isOpen }: ITopic): JSX.Element => {
  const { isTrue: questionsOpen, toggle: toggleQuestions } = useToggleState(isOpen)

  return <Card className="dancing faq__card">
    <CardHeader
      id="toggler-questions"
      className="faq__topic-layer__collapsable-card"
      onClick={toggleQuestions}
      aria-expanded={questionsOpen ? "true" : "false"}
    >
      <Button
        id="faq-topic-card-toggler"
        onClick={toggleQuestions}
      >
        <h3>{topicTitle}</h3>
      </Button>
      <span className={"toggle-icon" + (questionsOpen ? " toggle-icon-toggled" : "")}><Icon name="chevron-down" size={30} /></span>
    </CardHeader>
    <Collapse isOpen={questionsOpen}>
      <CardBody>
        <QuestionsOfTopic
          faqNode={faqNode}
          faqKey={faqKey}
        />
      </CardBody>
    </Collapse>
  </Card>
}

export default withDynamicNamespaces<ITopic>(FAQTopicCard, usedNamespaces)

// Helper - function

// provides clickable Buttons for every single FAQ-Question of the chosen topic
const QuestionsOfTopic = ({ faqNode, faqKey }: IQuestions): JSX.Element => {
  const setFAQKey = useSetFAQKey()

  return <div>{faqNode && Object.keys(faqNode)
    .filter(key => key !== FAQKeys.Title.valueOf() && hasProp(faqNode[key], FAQKeys.Question))
    .map((entryKey) => {
      return <div key={entryKey}>
        {/* a button: to be accessable by keyboard
          a link: wo look like a link
          a div: to drive the bottom border under every line of a multi-line text
          -> @todo: CSS rework @see also: FAQSearchView
           */}
        <Button className='faq__topic-layer__question-link'
          onClick={() => { setFAQKey(faqKey + "." + entryKey) }}
        >
          <div>
            <span className='looks-like-a-link'>{hasProp(faqNode[entryKey], FAQKeys.Question) && faqNode[entryKey].question}</span>
          </div>
        </Button>
      </div>
    })}
  </div>
}