import { captureException } from "@sentry/nextjs"
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios"
import getProp from "lodash/get"
import hasProp from "lodash/has"
import mapValues from "lodash/mapValues"
import Qs from "qs" // included by Axios

import { IModel } from "@api/schema"

import { BackendRequestErrors, OtherRequestErrors, RequestError, translateRequestError } from "./requestError"
import { SubmissionError } from "../services/submissionError"

export const LD_MIME_TYPE = "application/ld+json"

export interface IViolation {
  message: string
  propertyPath: string
}

/**
 * implements an HydraClient based on Axios/an AxiosInstance
 */
export class HydraClient {
  // @todo public to allow setting interceptors, create an addInterceptor() instead?
  public axios: AxiosInstance
  /**
   * to store error information before the error occurs and may be "swallowed" by the async saga system
   * this attribute is able to store the error (with stacktrace) to be evaluated later.
   */
  public errorContext: Error

  constructor(baseURL: string) {
    this.axios = axios.create({
      baseURL,
      headers: {
        "Accept": LD_MIME_TYPE,
        "Content-Type": LD_MIME_TYPE,
      },
    })

    // Format nested params correctly
    this.axios.interceptors.request.use(config => {
      config.paramsSerializer = params => {
        return Qs.stringify(params, {
          arrayFormat: "brackets",
          encode: false
        })
      }

      return config
    })
  }

  /**
   * Sends parameters per GET-method to the given endpoint-URL, configured by the given config
   */
  public get = <ReturnType>(url: string, params: Record<string, unknown> = {}, config: AxiosRequestConfig = {}): Promise<ReturnType> => {
    config.method = "get"
    config.params = params

    return this.request(url, config)
  }

  /**
   * Sends data per POST-method to the given endpoint-URL, configured by the given config
   *
   * @param url The URL of the backend-API-endpoint
   * @param data the data to be sent
   * @param config the configuration
   * @returns a Promise of requested data
   */
  public post = <ReturnType, DataType>(url: string, data: DataType, config: AxiosRequestConfig<string> = {}): Promise<ReturnType> => {
    config.method = "post"
    config.data = JSON.stringify(data)

    return this.request(url, config)
  }

  /**
   * Sends data per PATCH-method to the given endpoint-URL, configured by the given config
   */
  public patch = <ReturnType, DataType>(url: string, data: DataType, config: AxiosRequestConfig<string> = {}): Promise<ReturnType> => {
    config.method = "patch"
    config.data = JSON.stringify(data)
    config.headers = { 'Content-Type': 'application/merge-patch+json' }

    return this.request(url, config)
  }

  /**
   * Sends a signal per DELETE-method to the given endpoint-URL, configured by the given config
   */
  public delete = <ReturnType, DataType>(url: string, config: AxiosRequestConfig<DataType> = {}): Promise<ReturnType> => {
    config.method = "delete"

    return this.request(url, config)
  }

  /**
   * Sends an File encoded in the given data to the given endpoint-URL
   * to upload it.
   */
  public upload = <ReturnType, DataType extends FormData>(url: string, data: DataType, config: AxiosRequestConfig<DataType> = {}): Promise<ReturnType> => {
    config.method = config.method || "POST"
    config.data = data
    config.headers = {
      'Content-Type': data
        ? 'multipart/form-data'
        // when no data is posted, e.g. to delete the current file the content-type needs to be different,
        // else PHP / the API will return no headers (only content-type=text/html) and especially no CORS
        // headers and the handling of the response will fail while the request was correctly processed
        // on the server - fixes: FCP-210
        : 'application/x-www-form-urlencoded'
    }

    return this.request(url, config)
  }

  /**
   * sends an request to the given API-endpoint-URL
   */
  public request = async <ReturnType, DataType>(url: string, config: AxiosRequestConfig<DataType> = {}): Promise<ReturnType> => {
    const axiosConfig = {
      ...config,
      url,
    }

    this.errorContext = new Error("Hydraclient error requesting: " + url)

    // the Authorization header is added via interceptor
    try {
      const response = await this.axios.request<ReturnType>(axiosConfig)
      // @todo we don't want to flatten nested documents, some properties are only readable
      // as subresource, remove the normalize completely?
      // return this.normalize(response.data)
      return response.data
    } catch (err) {
      this.handleError(err)
    }
  }

  /**
   * Normalizes given hydra:member in the data and returns in in normalized form
   */
  protected normalize = (data: Record<string, any>): Record<string, any> => {
    if (hasProp(data, "hydra:member")) {
      // Normalize items in collections
      data["hydra:member"] = data["hydra:member"].map((item: IModel) => this.normalize(item))

      return data
    }

    // Flatten nested documents
    return mapValues(data, (value) =>
      Array.isArray(value)
        ? value.map((v) => getProp(v, "@id", v) as string)
        : getProp(value, "@id", value) as string,
    )
  }

  protected handleError = (err: AxiosError): void => {
    if (err.response) {
      const json: any = err.response.data

      // violations are encoded within the returned json by the backend
      //
      // json may not be set in test situations
      // on missing mocked API call
      if (json?.violations) {
        const errors = {}
        json.violations.map((violation: IViolation) => {
          this.addViolationToErrors(violation, errors, this.parsePropertyPath(violation.propertyPath))
        })

        throw new SubmissionError(errors)
      }

      // in case of missing data from api (usually: in test situations) no json (=data) is returned
      const msg: BackendRequestErrors =
        json?.["hydra:description"]
        || json?.message
        || err.response.statusText
        || OtherRequestErrors.MissingDataFromApi

      const missingDataError = new Error(translateRequestError(msg))
      // adding the prior saved error context as cause to have the option to show the stack trace
      missingDataError.cause = this.errorContext
      throw missingDataError
    }

    // @todo log RequestError for monitoring
    captureException(err)

    if (err.request) {
      // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      throw new RequestError(OtherRequestErrors.NoNetworkResponse)
    }

    throw new RequestError(OtherRequestErrors.FailureSendingRequest, { cause: err })
  }

  private addViolationToErrors = (violation: IViolation, errors: any, pathList: string[]): Record<string, unknown> | string => {
    if (pathList.length) {
      const prop = pathList.shift()
      const res = this.addViolationToErrors(violation, errors ? errors[prop] : null, pathList)
      if (errors) {
        errors[prop] = res
      } else {
        errors = { [prop]: res }
      }
      return errors as Record<string, unknown>
    } else {
      return errors
        ? errors as string + "\n" + violation.message
        : violation.message
    }
  }

  /**
   * API Platform returns a list of violations, one element for each failed constraint, in the form
   * of propertyPath => message. But if the property is an array it returns prop[i] => message,
   * if the property is nested it returns prop.subProp => message (or even prop[i].subProp),
   * which is not what redux-form expects in its SubmissionError. So we have to parse the path to build
   * a nested errors object.
   */
  private parsePropertyPath = (path: string): string[] => {
    let pathList: string[] = []
    let baseProp = path

    const nestStart = path.indexOf(".")
    if (nestStart > 0) {
      baseProp = baseProp.substring(0, nestStart)
    }

    const arrayPropRegex = /\[(.+?)]/g
    const arrayStart = path.indexOf("[")

    if (arrayStart > 0) {
      baseProp = baseProp.substring(0, arrayStart)
      pathList.push(baseProp)

      let matches: RegExpExecArray
      do {
        matches = arrayPropRegex.exec(path)
        if (matches) {
          pathList.push(matches[1])
        }
      } while (matches)
    } else {
      pathList.push(path)
    }

    pathList = nestStart > 0
      ? [...pathList, ...this.parsePropertyPath(path.substring(nestStart + 1))]
      : pathList

    return pathList
  }
}
